import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'
const ContinuousMonitoring = () => {
    return (
        <>
        <Header page={true} />
        <main className="main-area fix mt-5">
            <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
    <div>
    
    
    <section
      className="breadcrumb-area bg--cover "
      style={{
        backgroundImage:
          "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
      }}
    >
      <div className="container">
        <div className="breadcrumb-area__content">
          <h2>Commitment to Continuous Monitoring</h2>
          <nav aria-label="breadcrumb" className="breadcrumb">
            <span property="itemListElement" typeof="ListItem">
              <a
                property="item"
                typeof="WebPage"
                title="Go to Bitrader."
                href="/"
                className="home"
              >
                <span property="name">Home</span>
              </a>
              <meta property="position" content={1} />
            </span>{" "}
            &gt;{" "}
            <span property="itemListElement" typeof="ListItem">
              <span property="name" className="post post-teams current-item">
              Commitment to Continuous Monitoring
              </span>
              {/* <meta
                property="url"
                content="https://bitrader.thetork.com/teams/albert-flores/"
              />
              <meta property="position" content={3} /> */}
            </span>{" "}
          </nav>
        </div>
        <div className="breadcrumb-area__shape">
          <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
            <img
              src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
              alt="Image"
            />
          </span>
        </div>
      </div>
    </section>
    <div className='p-5 mt-5 mb-5'>
    
    <div className='row'>
    
    
   
        <div className='col-lg-4 col-sm-12 col-md-4'>
        <img src='/Images/screnner/2.jpg' className='pe-3' />
    
        </div>
        <div className='col-lg-7 col-sm-12 col-md-7'>
    
    <div
      className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
      data-id="2a404d7"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h2 className="elementor-size-default">
        Commitment to Continuous Monitoring
        </h2>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
      data-id="77c0134"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
        
      <div className="elementor-widget-container ">
        <p className="elementor-size-default">
        We understand the dynamic nature of financial markets and the evolving needs of ethical investors.
        Therefore, the Shariah Board periodically reviews and updates the screening norms to minimize interestbased transactions while ensuring the financial viability of portfolios.
        </p>
        <p className="elementor-size-default">
        IslamicTijarat™ ensures that any updates to these criteria are promptly communicated to our users and
stakeholders, maintaining complete transparency and trust.
        </p>
        <p className="elementor-size-default">
        By adopting the trusted methodology, we provide our users with the assurance of investing in ethically
        responsible and Shariah-compliant avenues.
        </p>
      </div>
    </div>
  

</div>
    </div>
    </div>
    
    </div>
                </div>
                </main>
    <Footer />
    </>
      )
}

export default ContinuousMonitoring