import React from 'react'

const Three = () => {
  return (
    <div
    className="elementor-element elementor-element-47b6712 e-flex e-con-boxed e-con e-parent"
    data-id="47b6712"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-3d03e0f e-con-full e-flex e-con e-child"
        data-id="3d03e0f"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-8a79fe6 elementor-widget elementor-widget-genix-image"
          data-id="8a79fe6"
          data-element_type="widget"
          data-widget_type="genix-image.default"
        >
          <div className="elementor-widget-container">
            <div
              className="about__thumb pe-lg-5"
              data-aos="fade-right"
              data-aos-duration={800}
            >
              <div className="about__thumb-inner">
                <div className="about__thumb-image floating-content">
                  <img
                    decoding="async"
                    src="https://bitrader.thetork.com/wp-content/uploads/2023/10/about01-2.png"
                    alt=""
                  />
                  {/* <div className="floating-content__top-left">
                    <div className="floating-content__item">
                      <h3>10 Years</h3>
                      <p>Consulting Experience</p>
                    </div>
                  </div> */}
                  {/* <div className="floating-content__bottom-right">
                    <div className="floating-content__item">
                      <h3>25K+</h3>
                      <p>Satisfied Customers</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-f496e11 e-con-full e-flex e-con e-child"
        data-id="f496e11"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
          data-id="2a404d7"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h2 className="elementor-heading-title elementor-size-default">
                 Start Your
              <span style={{ color: "var(--secondary-color)" }}> Ethical Investment</span>{" "}
              Journey
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
          data-id="77c0134"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <p className="elementor-size-default">
            Welcome to Islamic Tijarat.
Islamic Tijarat is dedicated to helping you make informed decisions with confidence, aligning your financial path with the values that matter most
Faith-Based Integrity – We meticulously screen each investment to ensure it aligns with Islamic principles, offering you transparency and peace of mind. Informed Choices – Receive detailed insights and clarity about Shariah compliance, empowering you to navigate the market with confidence. Trusted Expertise – With a team of seasoned scholars and financial experts, we provide dependable, principled guidance for every step of your financial journey.
            </p>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-211649c elementor-widget elementor-widget-tg-btn"
          data-id="211649c"
          data-element_type="widget"
          data-widget_type="tg-btn.default"
        >
          {/* <div className="elementor-widget-container">
            <div className="myclass1">
              <a
                href="https://bitrader.thetork.com/about-us/"
                target="_self"
                rel="nofollow"
                className="trk-btn trk-btn--border trk-btn--primary"
              >
                Explore More{" "}
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>  
  )
}

export default Three