import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const BusinessScreening = () => {
    return (
        <>
            <Header page={true} />
            <main className="main-area fix mt-5">
                <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
                    <div>


                        <section
                            className="breadcrumb-area bg--cover "
                            style={{
                                backgroundImage:
                                    "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
                            }}
                        >
                            <div className="container">
                                <div className="breadcrumb-area__content">
                                    <h2> Business Screening</h2>
                                    <nav aria-label="breadcrumb" className="breadcrumb">
                                        <span property="itemListElement" typeof="ListItem">
                                            <a
                                                property="item"
                                                typeof="WebPage"
                                                title="Go to Bitrader."
                                                href="/"
                                                className="home"
                                            >
                                                <span property="name">Home</span>
                                            </a>
                                            <meta property="position" content={1} />
                                        </span>{" "}
                                        &gt;{" "}
                                        <span property="itemListElement" typeof="ListItem">
                                            <span property="name" className="post post-teams current-item">
                                                Business Screening
                                            </span>
                                            {/* <meta
            property="url"
            content="https://bitrader.thetork.com/teams/albert-flores/"
          />
          <meta property="position" content={3} /> */}
                                        </span>{" "}
                                    </nav>
                                </div>
                                <div className="breadcrumb-area__shape">
                                    <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
                                        <img
                                            src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
                                            alt="Image"
                                        />
                                    </span>
                                </div>
                            </div>
                        </section>
                        <div className='p-5 mt-5 mb-5'>

                            <div className='row'>


                            <div className='col-lg-6 col-sm-12 col-md-6'>

<div
    className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
    data-id="2a404d7"
    data-element_type="widget"
    data-widget_type="heading.default"
>
    <div className="elementor-widget-container">
        <h2 className="elementor-size-default">
            Business Screening
        </h2>
    </div>
</div>
<div
    className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
    data-id="77c0134"
    data-element_type="widget"
    data-widget_type="heading.default"
>

    <div className="elementor-widget-container ">

        <p className="elementor-size-default">
            We strictly exclude investments in sectors deemed impermissible under Shariah, as outlined below:
        </p>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>1.Prohibited Sectors</span>

        </p>
        <p className="elementor-size-default">
            These industries are inherently impermissible under Shariah law and include:
        </p>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '40px' }}>
            <li>Conventional (interest-based) banking and financial services.</li>
            <li>Conventional (interest-based) insurance.</li>
            <li>Brokerage of interest-based financial products.</li>
            <li>Fund-based financial services.</li>
            <li>Manufacture, distribution, and sale of alcoholic beverages or narcotics.</li>
            <li>Processing, distribution, and sale of pork and pork-related products.</li>
            <li>Gambling and tobacco-related industries.</li>
        </ul>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>2.Conditionally Prohibited Sectors</span>

        </p>

        <p className="elementor-size-default">
            In the Indian context, certain industries are considered impermissible unless certified as Shariahcompliant by a reliable Shariah compliance monitoring authority. These include:
        </p>



        <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '40px' }}>
            <li>Meat processing and marketing industries.</li>
            <li>Sugar manufacturing units.</li>
            <li>Media broadcasting and entertainment industries.</li>
            <li>Diversified companies operating across multiple sectors.</li>
        </ul>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>3. Companies with Partial Prohibited Activities (Secondary Business)</span>

        </p>
        <p className="elementor-size-default">
            On a case-by-case basis, we exclude companies whose main business does not fall under the
            impermissible sectors but have some level of involvement in prohibited activities—either directly or
            through subsidiaries.
        </p>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>Permissible Investments</span>

        </p>
        <p className="elementor-size-default">
            Investments are permitted in companies that do not operate in the sectors or activities outlined above,
            ensuring alignment with Shariah principles.
        </p>

    </div>
</div>


</div>
                                <div className='col-lg-6 col-sm-12 col-md-6'>
                                    <img src='/Images/screnner/6.png' className='ps-3' />

                                </div>
                             
                            </div>
                        </div>

                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default BusinessScreening