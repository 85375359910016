import React from 'react'

const Threeteen = () => {
  return (
    <div
      className="elementor-element elementor-element-4305600 e-flex e-con-boxed e-con e-parent"
      data-id={4305600}
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-e162490 elementor-widget__width-auto elementor-absolute upAndDown_animation elementor-widget elementor-widget-image"
          data-id="e162490"
          data-element_type="widget"
          data-settings='{"_position":"absolute"}'
          data-widget_type="image.default"
        >
          <div className="elementor-widget-container">
            <img
              decoding="async"
              width={106}
              height={106}
              src="https://bitrader.thetork.com/wp-content/uploads/2023/10/shape01-2.png"
              className="attachment-full size-full wp-image-238"
              alt=""
            />
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d9a05dd e-flex e-con-boxed e-con e-child"
          data-id="d9a05dd"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-0c4fc74 elementor-widget elementor-widget-tg-heading"
              data-id="0c4fc74"
              data-element_type="widget"
              data-widget_type="tg-heading.default"
            >
              <div className="elementor-widget-container">
                <div className="section-title">
                  <h2 className="title">
                    <span>Frequently</span> Asked Questions
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-c1eab82 elementor-widget elementor-widget-heading"
              data-id="c1eab82"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <p className="elementor-heading-title elementor-size-default">
                  Hey there! Got questions? We've got answers. Check out our FAQ
                  page for all the deets. Still not satisfied? Hit us up.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-ddc8ef4 e-con-full e-flex e-con e-child"
          data-id="ddc8ef4"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-3359668 e-flex e-con-boxed e-con e-child"
            data-id={3359668}
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-16d8d15 elementor-widget elementor-widget-genix-faq"
                data-id="16d8d15"
                data-element_type="widget"
                data-widget_type="genix-faq.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="accordion accordion--style1"
                    id="faqAccordion1"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq0">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody0"
                          aria-expanded="false"
                          aria-controls="faqBody0"
                        >
                          <span className="accordion__button-content">
                            What is Shariah-Compliant Investing?
                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody0"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq0"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            Shariah-compliant investing prohibits engaging in businesses or financial activities involving:
                          </p>
                          <p className="mb-15">
                            1. Riba (interest): Prohibited earnings from interest.
                          </p>
                          <p className="mb-15">

                            2. Gharar (excessive uncertainty): Avoiding speculative or risky transactions.
                          </p>
                          <p className="mb-15">

                            3. Haram activities: Businesses related to alcohol, gambling, pork, and unethical practices.
                          </p>





                        </div>
                      </div>
                    </div>
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq1">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody1"
                          aria-expanded="false"
                          aria-controls="faqBody1"
                        >
                          <span className="accordion__button-content">
                            What financial ratios are used in Shariah screening?
                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody1"
                        className="accordion-collapse collapse "
                        aria-labelledby="faq1"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            Key ratios include debt-to-equity and interest income limits, with thresholds often below 30% to meet compliance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq2">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody2"
                          aria-expanded="false"
                          aria-controls="faqBody2"
                        >
                          <span className="accordion__button-content">
                            How are mixed-source companies treated?
                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody2"
                        className="accordion-collapse collapse "
                        aria-labelledby="faq2"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            If a company has minor haram income, it might still qualify if purification (donating the prohibited portion) is practiced.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq3">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody3"
                          aria-expanded="false"
                          aria-controls="faqBody3"
                        >
                          <span className="accordion__button-content">
                            What happens if a company loses compliance?
                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody3"
                        className="accordion-collapse collapse "
                        aria-labelledby="faq3"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            Investors typically have a grace period to sell shares when a stock falls out of Shariah compliance.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq4">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody4"
                          aria-expanded="false"
                          aria-controls="faqBody4"
                        >
                          <span className="accordion__button-content">
                            Can Shariah-compliant investments include real estate?

                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody4"
                        className="accordion-collapse collapse "
                        aria-labelledby="faq4"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            Yes, real estate investments are allowed as long as they exclude properties used for prohibited activities, like casinos.

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion__item ">
                      <div className="accordion__header" id="faq5">
                        <button
                          className="accordion__button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody5"
                          aria-expanded="false"
                          aria-controls="faqBody5"
                        >
                          <span className="accordion__button-content">
                            How are Islamic financial products certified?

                          </span>
                          <span className="accordion__button-plusicon" />
                        </button>
                      </div>
                      <div
                        id="faqBody5"
                        className="accordion-collapse collapse "
                        aria-labelledby="faq5"
                        data-bs-parent="#faqAccordion1"
                      >
                        <div className="accordion__body">
                          <p className="mb-15">
                            Shariah boards of scholars review and certify products for compliance, ensuring they meet Islamic financial principles.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-00b51e6 e-flex e-con-boxed e-con e-child"
            data-id="00b51e6"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-b4d20d2 faq__thumb--style1 elementor-widget elementor-widget-image"
                data-id="b4d20d2"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    loading="lazy"
                    decoding="async"
                    width={443}
                    height={370}
                    src="/Images/Icone/FAQ.png"
                    className="attachment-full size-full wp-image-301"
                    alt=""
                    srcSet="/Images/Icone/FAQ.png 443w, /Images/Icone/FAQ.png 300w"
                    sizes="(max-width: 443px) 100vw, 443px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Threeteen