import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const MuftiBarkatullah = () => {
  return (
    <>
      <Header page={true} />
      <main className="main-area fix mt-5">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
          <div>


            <section
              className="breadcrumb-area bg--cover "
              style={{
                backgroundImage:
                  "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
              }}
            >
              <div className="container">
                <div className="breadcrumb-area__content">
                  <h2>Mufti Barkatullah Abdul Kadir</h2>
                  <nav aria-label="breadcrumb" className="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a
                        property="item"
                        typeof="WebPage"
                        title="Go to Bitrader."
                        href="/"
                        className="home"
                      >
                        <span property="name">Home</span>
                      </a>
                      <meta property="position" content={1} />
                    </span>{" "}
                    &gt;{" "}
                    <span property="itemListElement" typeof="ListItem">
                      <span property="name" className="post post-teams current-item">
                        Mufti Barkatullah Abdul Kadir
                      </span>
                      {/* <meta
            property="url"
            content="https://bitrader.thetork.com/teams/albert-flores/"
          />
          <meta property="position" content={3} /> */}
                    </span>{" "}
                  </nav>
                </div>
                <div className="breadcrumb-area__shape">
                  <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
                    <img
                      src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
                      alt="Image"
                    />
                  </span>
                </div>
              </div>
            </section>
            <div className='p-5 mt-5 mb-5'>

              <div className='row'>
                <div className='col-lg-4 col-sm-12 col-md-4'>
                  <img src='/Images/team/Mufti_Barkatullah_Abdul_Kadir.png' className='pe-3' />

                </div>

                <div className='col-lg-7 col-sm-12 col-md-7'>

                  <div
                    className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
                    data-id="2a404d7"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-size-default">
                        Mufti Barkatullah Abdul Kadir
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
                    data-id="77c0134"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <p className="elementor-size-default">
                        Mufti Barkatullah is a prominent Shariah scholar with a strong background in
                        economics and finance. He received an MPhil in Informatics from the
                        University of Wales (UK). He holds BSc Honors in Economics from London
                        and has also received his Mufti (Diploma) in Islamic Law from Darul Ifta,
                        Deoband, India. Trained extensively both in the traditional Islamic and
                        modern Western educational traditions, Mufti has served as Sharia Judge at
                        the Islamic Sharia Council, London. He is a member of the Shariah supervisory
                        boards for several UK based financial institutions such as Islamic Bank of
                        Britain, Alburaq of ABC Bank International (UK), United National Bank, Lloyds
                        TSB, Scottish Widows Investment Partnership Islamic SICAV, Luxembourg
                        and British Islamic Insurance Holdings (UK). He has been a lecturer at Ebrahim
                        Community College, London. As a broadcaster and maintainer of Islamic
                        Helpline, he has a wealth of experience in Islamic financial issues. He received
                        praise from the British Parliament for his immense contribution to Islamic
                        Finance in UK. He has also been awarded for Best Research Thesis in 1992 by
                        Oxford Academy. He has also been involved in community service as Sharia
                        Judge at Islamic Sharia Council, London.
                      </p>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default MuftiBarkatullah