import React, { useEffect, useState } from "react";

const Header = (page) => {
  console.log('page',page.page);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [Opennavbar, setOpennavbar] = useState(false);
  const [Homemenu, setHomemenu] = useState(false);
  const [Servicesmenu, setServicesmenu] = useState(false);
  const [Pagesmenu, setPagesmenu] = useState(false);
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-bs-theme'));

  useEffect(() => {
    // Function to check and update the theme
    const checkThemeChange = () => {
      const newTheme = document.documentElement.getAttribute('data-bs-theme');
      if (newTheme !== theme) {
        setTheme(newTheme);
      }
    };

    // Observe changes to the `data-bs-theme` attribute
    const mutationObserver = new MutationObserver(checkThemeChange);

    mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme'],
    });

    // Cleanup the observer on component unmount
    return () => {
      mutationObserver.disconnect();
    };
  }, [theme]); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="sticky-header"
      className={`header-section header-section--style2 ${page?.page ?"header-fixed fadeInUp" :""} ${
        isHeaderFixed ? "header-fixed fadeInUp" : ""
      }`}
    >
    <div className="header-bottom">
      <div className="container">
        <div className="header-wrapper">
          <div className="logo">
            <a className="main-logo" href="/">
              <img
                id="main-logo"
                className="dark"
                src={theme == 'dark' ? "/Images/darklogofotter.png":"/Images/lightlogohead.png"}
                style={{ maxHeight: 43 }}
                alt="Logo"
              />
            </a>
          </div>
          <div className="menu-area menu--style1" style={{textAlign:"left"}}>
            <ul id="menu-main-menu" className={`menu menu ${Opennavbar ? 'active' :""}`}>
              <li
                itemScope="itemscope"
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-826"
                className={`menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children dropdown has-dropdown active menu-item-826 nav-item ${Homemenu ?"open":''}`}
             onClick={()=>{setHomemenu(!Homemenu)}} >
                <a title="Home" href="#" className="nav-links">
                  Home
                </a>     
                <ul className={`submenu ${Homemenu ?"d-block":"d-none"}`} role="menu">
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-816"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-67 current_page_item active menu-item-816 nav-item`}
                  >
                    <a
                      title="Home Stock"
                      href="#"
                      className="dropdown-items"
                    >
                      Home Stock
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-821"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-821 nav-item"
                  >
                    <a
                      title="Home Crypto"
                      href="https://bitrader.thetork.com/home-two/"
                      className="dropdown-items"
                    >
                      Home Crypto
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-820"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-820 nav-item"
                  >
                    <a
                      title="Home Forex"
                      href="#"
                      className="dropdown-items"
                    >
                      Home Forex
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-1460"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1460 nav-item"
                  >
                    <a
                      title="Home Day Trading"
                      href="#"
                      className="dropdown-items"
                    >
                      Home Day Trading
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-1463"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1463 nav-item"
                  >
                    <a
                      title="Home Trading Platform"
                      href="#"
                      className="dropdown-items"
                    >
                      Home Trading Platform
                    </a>
                  </li>
                </ul>
              </li>
              <li
                itemScope="itemscope"
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-827"
                onClick={()=>{setServicesmenu(!Servicesmenu)}}
                className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown has-dropdown menu-item-827 nav-item ${Servicesmenu ? 'open':''}`}
              >
                <a title="Services" href="#" className="nav-links">
                  Services
                </a>
                <ul className={`submenu ${Servicesmenu ?'d-block':'d-none'}`} role="menu">
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-823"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-823 nav-item"
                  >
                    <a
                      title="Our Services"
                      href="#"
                      className="dropdown-items"
                    >
                      Our Services
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-828"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-828 nav-item"
                  >
                    <a
                      title="Services Details"
                      href="#"
                      className="dropdown-items"
                    >
                      Services Details
                    </a>
                  </li>
                </ul>
              </li>
              <li
                itemScope="itemscope"
                itemType="#"
                id="menu-item-818"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-818 nav-item"
              >
                <a
                  title="About Us"
                  href="#"
                  className="nav-links"
                >
                  About Us
                </a>
              </li>
              {/* <li
                itemScope="itemscope"
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-829"
                className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown has-dropdown menu-item-829 nav-item ${Pagesmenu?"open":''}`}
                onClick={()=>{setPagesmenu(!Pagesmenu)}}
              
              >
                <a title="Pages" href="#" className="nav-links">
                  Pages
                </a>
                <ul className={`submenu ${Pagesmenu ?'d-block':'d-none'}`} role="menu">
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-824"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-824 nav-item"
                  >
                    
                    <a
                      title="Our Team"
                      href="https://bitrader.thetork.com/our-team/"
                      className="dropdown-items"
                    >
                      Our Team
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-2351"
                    className="menu-item menu-item-type-post_type menu-item-object-teams menu-item-2351 nav-item"
                  >
                    <a
                      title="Team Details"
                      href="https://bitrader.thetork.com/teams/dianne-russell/"
                      className="dropdown-items"
                    >
                      Team Details
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-822"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-822 nav-item"
                  >
                    <a
                      title="Our Pricing"
                      href="https://bitrader.thetork.com/our-pricing/"
                      className="dropdown-items"
                    >
                      Our Pricing
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-817"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-817 nav-item"
                  >
                    <a
                      title="Blog"
                      href="https://bitrader.thetork.com/blog/"
                      className="dropdown-items"
                    >
                      Blog
                    </a>
                  </li>
                  <li
                    itemScope="itemscope"
                    itemType="https://www.schema.org/SiteNavigationElement"
                    id="menu-item-2576"
                    className="menu-item menu-item-type-post_type menu-item-object-post menu-item-2576 nav-item"
                  >
                    <a
                      title="Blog Details"
                      href="https://bitrader.thetork.com/options-trading-business/"
                      className="dropdown-items"
                    >
                      Blog Details
                    </a>
                  </li>
                </ul>
              </li> */}
              <li
                itemScope="itemscope"
                itemType="https://www.schema.org/SiteNavigationElement"
                id="menu-item-819"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-819 nav-item"
              >
                <a
                  title="Contact Us"
                  href="#"
                  className="nav-links"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="header-action">
            <div className="menu-area">
              <div className="header-btn">
                <a
                  href="#"
                 
                  className="trk-btn trk-btn--border trk-btn--primary"
                >
                  <span>Join Now</span>
                </a>
              </div>
              {/* toggle icons */}
              <div className={`header-bar d-lg-none header-bar--style1 ${Opennavbar ? 'active':""}`}  onClick={()=>{setOpennavbar(!Opennavbar)}}>
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  )
}

export default Header