import React from 'react'

const Loader = () => {
  return (
    <>
    {/* <div className="preloader">
      <img
        src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/logo/preloader.png"
        alt="Preloader"
      />
    </div> */}
    {/* preloader-end */}
    {/* <a
      href="javascript:void(0)"
      className="scrollToTop scrollToTop--style1 scroll__top scroll-to-target scrollToTop--home1"
      data-target="html"
    >
      <i className="fas fa-angle-up" />
    </a> */}
  </>
  
  )
}

export default Loader