import React, { useEffect } from "react";

const TradingViewTickerTape = () => {
  useEffect(() => {
    // Dynamically create the script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.innerHTML = JSON.stringify({
      symbols: [
        { proName: "FOREXCOM:SPXUSD", title: "S&P 500 Index" },
        { proName: "FOREXCOM:NSXUSD", title: "US 100 Cash CFD" },
        { proName: "FX_IDC:EURUSD", title: "EUR to USD" },
        { proName: "BITSTAMP:BTCUSD", title: "Bitcoin" },
        { proName: "BITSTAMP:ETHUSD", title: "Ethereum" },
      ],
      showSymbolLogo: true,
      isTransparent: true,
      displayMode: "adaptive",
      colorTheme: "light",
      locale: "en",
    });
    // Append the script to the container
    const container = document.getElementById("tradingview-widget-container");
    container.appendChild(script);

    // Cleanup function to remove script when component unmounts
    return () => {
      container.innerHTML = ""; // Remove script and widget elements
    };
  }, []);

  return (
    <div className="tradingview-widget-container" id="tradingview-widget-container"  style={{height:'50px'}}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
      </div>
    </div>
  );
};

export default TradingViewTickerTape;
