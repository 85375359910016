import React from 'react'

const Eight = () => {
  return (
<div
  className="elementor-element elementor-element-5e5939c bg-color e-flex e-con-boxed e-con e-parent"
  data-id="5e5939c"
  data-element_type="container"
>
  <div className="e-con-inner">
    <div
      className="elementor-element elementor-element-1d99910 e-flex e-con-boxed e-con e-child"
      data-id="1d99910"
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-a8e3aab elementor-widget elementor-widget-tg-heading"
          data-id="a8e3aab"
          data-element_type="widget"
          data-widget_type="tg-heading.default"
        >
          <div className="elementor-widget-container">
            <div className="section-title">
              <h2 className="title">
                Meet Our <span>Advisers</span>
              </h2>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-24689f2 elementor-widget elementor-widget-heading"
          data-id="24689f2"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <p className="elementor-heading-title elementor-size-default">
              Hey everyone, meet our amazing advisers! They're here to help and
              guide us through anything.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-8b08bdd elementor-widget elementor-widget-tg-team"
      data-id="8b08bdd"
      data-element_type="widget"
      data-widget_type="tg-team.default"
    >
      <div className="elementor-widget-container">
        <div className="team__wrapper">
          <div className="row g-4 align-items-center">
          <div className="col-sm-6 col-lg-3">
              <div
                className="team__item team__item--shape"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="team__item-inner team__item-inner--shape">
                  <div className="team__item-thumb team__item-thumb--style1">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={450}
                      height={592}
                       src="/Images/team/Mufti_Khalid_Saifullah_Rahmani.png"
                      className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                        srcSet="/Images/team/Mufti_Khalid_Saifullah_Rahmani.png 450w, /Images/team/Mufti_Khalid_Saifullah_Rahmani.png 228w"
                      sizes="(max-width: 450px) 100vw, 450px"
                    />
                  </div>
                  <div className="team__item-content team__item-content--style1">
                    <div className="team__item-author team__item-author--style1">
                      <div className="team__item-authorinfo">
                        <h6 className="mb-1">
                          <a
                            className="stretched-link"
                            href="/Mufti_Khalid_Saifullah_Rahmani"
                          >
                           Mufti Khalid Saifullah Rahmani
                          </a>
                        </h6>
                        {/* <p className="mb-0">Trade Captain</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div className="col-sm-6 col-lg-3">
              <div
                className="team__item team__item--shape"
                data-aos="fade-up"
                data-aos-duration={900}
              >
                <div className="team__item-inner team__item-inner--shape">
                  <div className="team__item-thumb team__item-thumb--style1">
                    <img
                      decoding="async"
                      width={450}
                      height={592}
                     src="/Images/team/Mufti_Barkatullah_Abdul_Kadir.png"
                      className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                      srcSet="/Images/team/Mufti_Barkatullah_Abdul_Kadir.png 450w, /Images/team/Mufti_Barkatullah_Abdul_Kadir.png 228w"
                      sizes="(max-width: 450px) 100vw, 450px"
                    />
                  </div>
                  <div className="team__item-content team__item-content--style1">
                    <div className="team__item-author team__item-author--style1">
                      <div className="team__item-authorinfo">
                        <h6 className="mb-1">
                          <a
                            className="stretched-link"
                            href="/Mufti_Barkatullah_Abdul_Kadir"
                          >
                       Mufti Barkatullah Abdul Kadir
                          </a>
                        </h6>
                        {/* <p className="mb-0">Strategic Advisor</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div
                className="team__item team__item--shape"
                data-aos="fade-up"
                data-aos-duration={800}
              >
                <div className="team__item-inner team__item-inner--shape">
                  <div className="team__item-thumb team__item-thumb--style1">
                    <img
                      fetchpriority="high"
                      decoding="async"
                      width={450}
                      height={592}
                      src="/Images/team/Hafiz_Mohammad.png"
                      className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                      srcSet="/Images/team/Hafiz_Mohammad.png 450w, /Images/team/Hafiz_Mohammad.png 228w"
                      sizes="(max-width: 450px) 100vw, 450px"
                    />
                  </div>
                  <div className="team__item-content team__item-content--style1">
                    <div className="team__item-author team__item-author--style1">
                      <div className="team__item-authorinfo">
                        <h6 className="mb-1">
                          <a
                            className="stretched-link"
                            href="/Hafiz_Mohammad_Iqbal"
                          >
                       Dr. Hafiz Mohammad Iqbal Masood Al-Nadvi
                          </a>
                        </h6>
                        {/* <p className="mb-0">Management Consultant</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-sm-6 col-lg-3">
              <div
                className="team__item team__item--shape"
                data-aos="fade-up"
                data-aos-duration={1100}
              >
                <div className="team__item-inner team__item-inner--shape">
                  <div className="team__item-thumb team__item-thumb--style1">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={450}
                      height={592}
                      src="/Images/team/Mufti_Mohammed_Ashfaq_Kazi.png"
                      className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                      alt=""
                         srcSet="/Images/team/Mufti_Mohammed_Ashfaq_Kazi.png 450w, /Images/team/Mufti_Mohammed_Ashfaq_Kazi.png 228w"
                      sizes="(max-width: 450px) 100vw, 450px"
                    />
                  </div>
                  <div className="team__item-content team__item-content--style1">
                    <div className="team__item-author team__item-author--style1">
                      <div className="team__item-authorinfo">
                        <h6 className="mb-1">
                          <a
                            className="stretched-link"
                            href="/Mufti_Mohammed_Ashfaq_Kazi"
                          >
                          Mufti Mohammed Ashfaq Kazi
                          </a>
                        </h6>
                        {/* <p className="mb-0">Financial Advisor</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-e49dbd7 e-con-full e-flex e-con e-child"
      data-id="e49dbd7"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-8101264 elementor-widget elementor-widget-tg-btn"
        data-id={8101264}
        data-element_type="widget"
        data-widget_type="tg-btn.default"
      >
        {/* <div className="elementor-widget-container">
          <div className="myclass1">
            <a
              href="https://bitrader.thetork.com/our-team/"
              target="_self"
              rel="nofollow"
              className="trk-btn trk-btn--border trk-btn--primary"
            >
              View more{" "}
            </a>
          </div>
        </div> */}
      </div>
    </div>
  </div>
</div>

  )
}

export default Eight