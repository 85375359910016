import React from 'react'
import Loader from '../Common/Loader'
import Header from '../Common/Header'
import Banner from '../pages/Splite/Banner'
import Partner from '../pages/Splite/Partner'
import Three from '../pages/Splite/Three'
import Four from '../pages/Splite/Four'
import Five from '../pages/Splite/Five'
import Six from '../pages/Splite/Six'
import Seven from '../pages/Splite/Seven'
import Eight from '../pages/Splite/Eight'
import Nine from '../pages/Splite/Nine'
import Ten from '../pages/Splite/Ten'
import Eleven from '../pages/Splite/Eleven'
import Threeteen from '../pages/Splite/Threeteen'
import Fifteen from '../pages/Splite/Fifteen'
import Footer from '../Common/Footer'


const Home = () => {
  return (
    <div>
      <Loader />
      <Header page={false}/>

      <main className="main-area fix">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
          <Banner />
          <Partner />
          <Three />
          <Five />
          <Four />
          <Six />
          <Seven />
          <Eight />
          <Nine />
          {/* <Ten /> */}
          <Eleven />
          <Threeteen />
          {/* <Fifteen /> */}
          <Footer />
        </div>
      </main>
    </div>
  )
}

export default Home