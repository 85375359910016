import logo from './logo.svg';
import './App.css';
import Home from './Component/pages/Home';
import Iqbal from './Component/pages/team/Iqbal'
import MuftiBarkatullah from './Component/pages/team/MuftiBarkatullah'
import MuftiKhalid from './Component/pages/team/MuftiKhalid';
import MuftiMohammedAshfaq from './Component/pages/team/MuftiMohammedAshfaq';
import ScreeningCriteria from './Component/pages/Screening/ScreeningCriteria';
import BusinessScreening from './Component/pages/Screening/BusinessScreening';
import FinancialScreening from './Component/pages/Screening/FinancialScreening';
import WhyChoose from './Component/pages/Screening/WhyChoose';
import ContinuousMonitoring from './Component/pages/Screening/ContinuousMonitoring';
import Purification from'./Component/pages/Articals/Purificationartical';
import ShariahCompliantInvesting from './Component/pages/Articals/ShariahCompliantInvesting';
import ScreeningCriteriapage from './Component/pages/Articals/ScreeningCriteria';
import {
  BrowserRouter as Router,Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

function App() {

  const routes = [
    { path: "/", element: <Home /> },
    { path: "/Hafiz_Mohammad_Iqbal", element:<Iqbal />},
    { path: "/Mufti_Barkatullah_Abdul_Kadir", element:<MuftiBarkatullah />},
    { path: "/Mufti_Khalid_Saifullah_Rahmani", element:<MuftiKhalid />},
    { path: "/Mufti_Mohammed_Ashfaq_Kazi", element:<MuftiMohammedAshfaq />},
    { path: "/Screening_Criteria", element:<ScreeningCriteria />},
    { path: "/Business_Screening", element:<BusinessScreening />},
    { path: "/Commitment_Continuous_Monitoring", element:<ContinuousMonitoring />},
    { path: "/Financial_Screening_Parameters", element:<FinancialScreening />},
    { path: "/Why_Choose_IslamicTijarat", element:<WhyChoose />},
    { path: "/Purification", element:<Purification />},
    { path: "/ShariahCompliantInvesting", element:<ShariahCompliantInvesting />},
    { path: "/ScreeningCriteriapage", element:<ScreeningCriteriapage />},
    
    
    
  ];
  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
  );
}

export default App;