import React, { useEffect, useState } from "react";

const Footer = () => {


  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-bs-theme'));

  useEffect(() => {
    // Function to check and update the theme
    const checkThemeChange = () => {
      const newTheme = document.documentElement.getAttribute('data-bs-theme');
      if (newTheme !== theme) {
        setTheme(newTheme);
      }
    };

    // Observe changes to the `data-bs-theme` attribute
    const mutationObserver = new MutationObserver(checkThemeChange);

    mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme'],
    });

    // Cleanup the observer on component unmount
    return () => {
      mutationObserver.disconnect();
    };
  }, [theme]); 

  return (
    <footer
    itemType="https://schema.org/WPFooter"
    itemScope="itemscope"
    id="colophon"
    role="contentinfo"
  >
    <div className="footer-width-fixer">
      <div
        data-elementor-type="wp-post"
        data-elementor-id={367}
        className="elementor elementor-367"
      >
        <div
          className="elementor-element elementor-element-e7278ee e-flex e-con-boxed e-con e-parent"
          data-id="e7278ee"
          data-element_type="container"
          data-settings='{"background_background":"classic"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-ca3a5d8 elementor-widget__width-inherit elementor-absolute h-100 elementor-widget elementor-widget-bg-shapes"
              data-id="ca3a5d8"
              data-element_type="widget"
              data-settings='{"_position":"absolute"}'
              data-widget_type="bg-shapes.default"
            >
              <div className="elementor-widget-container">
                <div className="footer__shape">
                  <span className="footer__shape-item footer__shape-item--2">
                    <span />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-bd984a0 e-con-full e-flex e-con e-child"
              data-id="bd984a0"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-455b42f e-con-full e-flex e-con e-child "
                data-id="455b42f"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-1e70553 elementor-widget__width-initial elementor-widget elementor-widget-site-logo w-100"
                  data-id="1e70553"
                  data-element_type="widget"
                  data-settings='{"align":"left","width":{"unit":"px","size":"","sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                  data-widget_type="site-logo.default"
                >
                  <div className="elementor-widget-container">
                    <div className="hfe-site-logo">
                      <a
                        data-elementor-open-lightbox=""
                        className="elementor-clickable"
                        href="https://bitrader.thetork.com"
                      >
                        <div className="hfe-site-logo-set">
                          <div className="hfe-site-logo-container">
                            <img
                              className="hfe-site-logo-img elementor-animation-"
                              src={theme == 'dark' ? "/Images/darklogofotter.png":"/Images/lightlogofotter.png"}
                              alt="logo-dark"
                              style={{height:"46px",width:"300px"}}
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-367b8d7 elementor-widget elementor-widget-heading"
                  data-id="367b8d7"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <p className="elementor-heading-title elementor-size-default">
                    Empowering Ethical Trade Worldwide


                    </p>
               
                    <h5 className="elementor-heading-title elementor-size-default text-white">
                    Address
                    </h5>
            
                    <p className="elementor-heading-title elementor-size-default">
                    Shop No.2, Pearl Oasis Apartment,
Pathanwadi, Malad East,
Mumbai - 400097
                    </p>
              


                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-b5d045a e-grid e-con-full e-con e-child"
                  data-id="b5d045a"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-7554086 elementor-widget elementor-widget-image"
                    data-id={7554086}
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <a href="#">
                        <img
                          width={100}
                          height={36}
                          src="https://bitrader.thetork.com/wp-content/uploads/2023/10/App-store-3.png"
                          className="attachment-full size-full wp-image-1200"
                          alt=""
                        />{" "}
                      </a>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-596b301 elementor-widget elementor-widget-image"
                    data-id="596b301"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <a href="#">
                        <img
                          width={100}
                          height={36}
                          src="https://bitrader.thetork.com/wp-content/uploads/2023/10/Google-play-store-2.png"
                          className="attachment-full size-full wp-image-1201"
                          alt=""
                        />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7c023eb e-con-full ms-auto e-flex e-con e-child"
                data-id="7c023eb"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-ec42b89 elementor-widget elementor-widget-heading"
                  data-id="ec42b89"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      Quick links
                    </h5>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-55bef3a elementor-list-item-link-inline elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                  data-id="55bef3a"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                          Home
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">Screener</span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Watchlist
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                           Premium
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                          Contact Us
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-fadd85c e-con-full e-flex e-con e-child"
                data-id="fadd85c"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-66a2b33 elementor-widget elementor-widget-heading"
                  data-id="66a2b33"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      Support
                    </h5>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-5a4d818 elementor-list-item-link-inline elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                  data-id="5a4d818"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Terms &amp; Conditions
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Privacy Policy
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">Refund Policy</span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                        FAQ
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7d2bbdd e-con-full e-flex e-con e-child"
                data-id="7d2bbdd"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-06c894c elementor-widget elementor-widget-heading"
                  data-id="06c894c"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      Company
                    </h5>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-ce52651 elementor-list-item-link-inline elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list"
                  data-id="ce52651"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Careers
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Updates
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">Job</span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Announce
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-39c3f15 e-con-full e-flex e-con e-child"
              data-id="39c3f15"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-c35f4f8 elementor-widget elementor-widget-heading"
                data-id="c35f4f8"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <p className="elementor-heading-title elementor-size-default">
                    © 2024 All Rights Reserved By Islamic Tijarat<sup>TM</sup>
                  </p>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-9b0d4fd elementor-widget elementor-widget-sociallist"
                data-id="9b0d4fd"
                data-element_type="widget"
                data-widget_type="sociallist.default"
              >
                <div className="elementor-widget-container">
                  <ul className="social list-wrap">
                    <li className="social__item footer">
                      <a href="https://www.facebook.com/islamictijarat" target='blank' className="social__link social__link--style22">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fab-facebook-f"
                          viewBox="0 0 320 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                        </svg>
                      </a>
                    </li>
                    <li className="social__item footer">
                      <a href="https://www.instagram.com/islamictijarat/" target='blank' className="social__link social__link--style22">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fab-instagram"
                          viewBox="0 0 448 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                        </svg>
                      </a>
                    </li>
                    <li className="social__item footer">
                      <a href="https://www.linkedin.com/in/islamictijarat"  target='blank'  className="social__link social__link--style22">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fab-linkedin-in"
                          viewBox="0 0 448 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                        </svg>
                      </a>
                    </li>
                    <li className="social__item footer">
                      <a href="https://www.youtube.com/islamictijarat" target='blank'  className="social__link social__link--style22">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fab-youtube"
                          viewBox="0 0 576 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                        </svg>
                      </a>
                    </li>
   <li className="social__item footer">
  <a href="https://x.com/islamictijarat" target='blank'  className="social__link social__link--style22">
    <svg
      aria-hidden="true"
      viewBox="0 0 384 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M365.4 19.6c-5.2-5.1-13.5-5.1-18.7 0L192 174.3 37.4 19.6c-5.2-5.1-13.5-5.1-18.7 0l-16.9 16.8c-5.2 5.1-5.2 13.5 0 18.6L156.3 256 1.8 419.1c-5.2 5.1-5.2 13.5 0 18.6l16.9 16.8c5.2 5.1 13.5 5.1 18.7 0L192 337.7l154.6 117.6c5.2 5.1 13.5 5.1 18.7 0l16.9-16.8c5.2-5.1 5.2-13.5 0-18.6L227.7 256l154.5-201.9c5.2-5.1 5.2-13.5 0-18.6l-16.9-16.8z"></path>
    </svg>
  </a>
</li>
  <li className="social__item footer">
  <a
    href="https://www.whatsapp.com/channel/0029VakHKaFJENy4EzxloA13"
    target="_blank"
    rel="noopener noreferrer"
    className="social__link social__link--style22"
  >
    <svg
      aria-hidden="true"
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M380.9 97.1C339.2 55.2 283.3 32 224 32 103.5 32 8 127.3 8 247.9c0 45.4 12 90.3 34.9 129.2L0 480l108.4-40.8c37.6 20.9 80.4 31.7 123.6 31.7h.1c120.5 0 215.9-95.3 215.9-215.9 0-59.3-23.1-115.2-65.1-157zM224 392.3c-35.5 0-70.6-9.5-101.2-27.5l-7.3-4.4-63.3 23.8 16.9-61.8-4.8-7.8C44.4 284.4 32 255 32 247.9 32 141.6 117.4 56 224 56c54.2 0 104.9 21.1 143.3 59.4 38.3 38.3 59.4 89.1 59.4 143.3-.1 106.4-85.5 191.7-191.7 191.7zm101.1-138.5c-5.5-2.8-32.7-16.1-37.8-17.9-5.1-1.8-8.8-2.8-12.5 2.8-3.7 5.5-14.3 17.9-17.5 21.6-3.2 3.7-6.4 4.2-11.9 1.4-32.7-16.4-54.1-29.1-75.8-65.8-5.7-9.8 5.7-9.1 16.4-30.2 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.2-17.1-41.5-4.5-10.7-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.9 6.9-5.1 5.5-19.7 19.3-19.7 47.2s20.2 54.7 23 58.5c2.8 3.7 39.7 60.7 96.2 85.2 13.5 5.9 24.1 9.4 32.3 12 13.5 4.3 25.9 3.7 35.7 2.3 10.9-1.6 33.7-13.8 38.5-27.2 4.8-13.4 4.8-24.9 3.4-27.2-1.3-2.3-5.1-3.7-10.6-6.4z"
      ></path>
    </svg>
  </a>
</li>

  <li className="social__item footer">
    <a href="https://t.me/islamictijarat"  target='blank' className="social__link social__link--style22">
      <svg
        aria-hidden="true"
        viewBox="0 0 496 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M248 8C111.033 8 0 119.033 0 256s111.033 248 248 248 248-111.033 248-248S384.967 8 248 8zm113.307 165.16L360.29 354.007c-3.06 12.868-12.296 15.958-24.004 9.96l-66.223-48.963-31.934 30.787c-3.532 3.533-6.477 6.477-13.052 6.477l4.688-67.284 122.361-110.229c5.36-5.36-1.17-8.361-8.322-5.288L153.26 276.532l-66.584-20.9c-14.423-4.484-14.652-14.423 3.018-21.283L353.38 147.94c11.672-4.483 21.883 2.802 18.094 17.22z"></path>
      </svg>
    </a>
  </li>

  {/* LinkedIn */}
  
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-1ceb9e5 elementor-widget__width-auto elementor-absolute footer__shape-item--1 elementor-widget elementor-widget-image"
              data-id="1ceb9e5"
              data-element_type="widget"
              data-settings='{"_position":"absolute"}'
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  width={81}
                  height={40}
                  src="https://bitrader.thetork.com/wp-content/uploads/2023/10/footer_shape-2.png"
                  className="attachment-full size-full wp-image-419"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  
  )
}

export default Footer