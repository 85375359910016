import React from 'react'

const Six = () => {
  return (
    <div
    className="elementor-element elementor-element-5482bb2 bg-color e-flex e-con-boxed e-con e-parent"
    data-id="5482bb2"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-a229896 elementor-widget__width-inherit elementor-absolute h-100 elementor-widget elementor-widget-bg-shapes"
        data-id="a229896"
        data-element_type="widget"
        data-settings='{"_position":"absolute"}'
        data-widget_type="bg-shapes.default"
      >
        <div className="elementor-widget-container">
          <div className="roadmap--style1">
            <span className="roadmap__shape-item roadmap__shape-item--1">
              <span />
            </span>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-8a1b9ae elementor-widget__width-auto elementor-absolute upAndDown_animation elementor-widget elementor-widget-image"
        data-id="8a1b9ae"
        data-element_type="widget"
        data-settings='{"_position":"absolute"}'
        data-widget_type="image.default"
      >
        <div className="elementor-widget-container">
          <img
            decoding="async"
            width={106}
            height={106}
            src="https://bitrader.thetork.com/wp-content/uploads/2023/10/shape01-2.png"
            className="attachment-full size-full wp-image-238"
            alt=""
          />
        </div>
      </div>
      <div
        className="elementor-element elementor-element-0caae5d e-flex e-con-boxed e-con e-child"
        data-id="0caae5d"
        data-element_type="container"
      >
        <div className="e-con-inner">
          <div
            className="elementor-element elementor-element-6f737e6 elementor-widget elementor-widget-tg-heading"
            data-id="6f737e6"
            data-element_type="widget"
            data-widget_type="tg-heading.default"
          >
            <div className="elementor-widget-container">
              <div className="section-title">
                <h2 className="title">
                Screening <span>Process</span>
                </h2>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-c258034 elementor-widget elementor-widget-heading"
            data-id="c258034"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <p className="elementor-heading-title elementor-size-default">
              Stocks and IPOs must undergo rigorous screening to ensure compliance with these principles. Let’s explore how these processes differ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-3473502 elementor-widget elementor-widget-genix-roadmap"
        data-id={3473502}
        data-element_type="widget"
        data-widget_type="genix-roadmap.default"
      >
        <div className="elementor-widget-container">
          <div className="roadmap--style1">
            <div className="roadmap__wrapper">
              <div className="row gy-4 gy-md-0 gx-5">
                <div className="col-md-6 offset-md-6">
                  <div
                    className="roadmap__item ms-md-4 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-duration={800}
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h3>Our Shariah Screening Criteria</h3>
                          {/* <span>P1</span> */}
                        </div>
                        <p>
                        At IslamicTijarat™, we are driven by a singular vision: to empower ethical investors with the most robust 
                        and pure Shariah-compliant investment options...                      </p>
                        <div className="elementor-widget-container mt-4 d-flex justify-content-end">
  <div>
    <a
      href="/Screening_Criteria"
      target="_self"
      rel="nofollow"
      className="btn btn-primary btn-sm text-center trk-btn--primary"
    >
      Read More
    </a>
  </div>
</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div
                    className="roadmap__item roadmap__item--style2 ms-auto me-md-4 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-duration={800}
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h3>Business Screening</h3>
                          {/* <span>P2</span> */}
                        </div>
                        
                        <p>
                        We strictly exclude investments in sectors deemed impermissible under Shariah, as outlined below...                        </p>
                        <div className="elementor-widget-container mt-4 d-flex justify-content-start">
  <div>
    <a
      href="/Business_Screening"
      target="_self"
      rel="nofollow"
      className="btn btn-primary btn-sm text-center trk-btn--primary"
    >
      Read More
    </a>
  </div>
</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6">
                  <div
                    className="roadmap__item ms-md-4 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-duration={800}
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h3>Financial Screening Parameters</h3>
                          {/* <span>P3</span> */}
                        </div>
                        {/* <p  className='mb-2 mt-2 fw-bold' style={{color:"#7E66BC"}}>Both stocks and IPOs are analyzed using specific Shariah compliance ratios, such as:</p> */}
                        <p className=''>
                        In addition to the nature of the business, companies must meet stringent financial criteria to qualify as 
                        Shariah-compliant...
                        </p>
                        <div className="elementor-widget-container mt-4 d-flex justify-content-end">
  <div>
    <a
      href="/Financial_Screening_Parameters"
      target="_self"
      rel="nofollow"
      className="btn btn-primary btn-sm text-center trk-btn--primary"
    >
      Read More
    </a>
  </div>
</div>
                        

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div
                    className="roadmap__item roadmap__item--style2 ms-auto me-md-4 aos-init aos-animate"
                    data-aos="fade-right"
                    data-aos-duration={800}
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h3>Commitment to Continuous Monitoring</h3>
                          {/* <span>P4</span> */}
                        </div>
                    
                        <p>
                        We understand the dynamic nature of financial markets and the evolving needs of ethical investors...
                        </p>
                        <div className="elementor-widget-container mt-4 d-flex justify-content-start">
  <div>
    <a
      href="/Commitment_Continuous_Monitoring"
      target="_self"
      rel="nofollow"
      className="btn btn-primary btn-sm text-center trk-btn--primary"
    >
      Read More
    </a>
  </div>
</div>
          
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6">
                  <div
                    className="roadmap__item ms-md-4 aos-init aos-animate"
                    data-aos="fade-left"
                    data-aos-duration={800}
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h3>Why Choose IslamicTijarat™</h3>
                          {/* <span>P5</span> */}
                        </div>
                       
                        <p>
                        Unmatched Purity: Our screening process is one of the most stringent in the world, ensuring the 
                        highest level of compliance with Islamic financial principles...                       </p>

          <div className="elementor-widget-container mt-4 d-flex justify-content-end">
  <div>
    <a
      href="/Why_Choose_IslamicTijarat"
      target="_self"
      rel="nofollow"
      className="btn btn-primary btn-sm text-center trk-btn--primary"
    >
      Read More
    </a>
  </div>
</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Six