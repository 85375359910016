import React from 'react'

const Eleven = () => {
  return (
<div
  className="elementor-element elementor-element-7e85cf0 bg-color e-flex e-con-boxed e-con e-parent"
  data-id="7e85cf0"
  data-element_type="container"
>
  <div className="e-con-inner">
    <div
      className="elementor-element elementor-element-ddc66b8 e-con-full e-flex e-con e-child"
      data-id="ddc66b8"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-ade7381 e-con-full e-flex e-con e-child"
        data-id="ade7381"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-b35e7f8 elementor-widget elementor-widget-tg-heading"
          data-id="b35e7f8"
          data-element_type="widget"
          data-widget_type="tg-heading.default"
        >
          <div className="elementor-widget-container">
            <div className="section-title">
              <h2 className="title">
                Connect With <span>Our Clients</span>
              </h2>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-784c64c elementor-widget elementor-widget-heading"
          data-id="784c64c"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <p className="elementor-heading-title elementor-size-default">
              We love connecting with our clients to hear about their
              experiences and how we can improve.
            </p>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-6a653d4 e-con-full e-flex e-con e-child"
        data-id="6a653d4"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-7c5b38d elementor-widget elementor-widget-html"
          data-id="7c5b38d"
          data-element_type="widget"
          data-widget_type="html.default"
        >
          <div className="elementor-widget-container">
            <div className="section-header__action">
              <div className="swiper-nav justify-content-start justify-content-md-end">
                <button className="swiper-nav__btn testimonial__slider-prev">
                  <i className="fas fa-angle-left" />
                </button>
                <button className="swiper-nav__btn testimonial__slider-next active">
                  <i className="fas fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-1058383 elementor-widget elementor-widget-testimonial"
      data-id={1058383}
      data-element_type="widget"
      data-widget_type="testimonial.default"
    >
      <div className="elementor-widget-container">
        <div
          className="testimonial__wrapper"
          data-aos="fade-up"
          data-aos-duration={1000}
        >
          <div className="testimonial__slider swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial__item testimonial__item--style1">
                  <div className="testimonial__item-inner">
                    <div className="testimonial__item-content">
                      <p className="mb-0">
                        The above testimonial is about Martha Chumo, who taught
                        herself to code in one summer. This testimonial example
                        works because it allows prospective customers to see
                        themselves in Codeacademy’s current customer base.
                      </p>
                      <div className="testimonial__footer">
                        <div className="testimonial__author">
                          <div className="testimonial__author-thumb">
                            <img
                              decoding="async"
                              src="https://bitrader.thetork.com/wp-content/uploads/2023/10/testi01-2.png"
                              width={48}
                              alt=""
                            />
                          </div>
                          <div className="testimonial__author-designation">
                            <h6>Gerald Boettcher</h6>
                            <span>Trade Master </span>
                          </div>
                        </div>
                        <div className="testimonial__quote">
                          <span>
                            <i className="fas fa-quote-right" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testimonial__item testimonial__item--style1">
                  <div className="testimonial__item-inner">
                    <div className="testimonial__item-content">
                      <p className="mb-0">
                        The above testimonial is about Martha Chumo, who taught
                        herself to code in one summer. This testimonial example
                        works because it allows prospective customers to see
                        themselves in Codeacademy’s current customer base.
                      </p>
                      <div className="testimonial__footer">
                        <div className="testimonial__author">
                          <div className="testimonial__author-thumb">
                            <img
                              decoding="async"
                              src="https://bitrader.thetork.com/wp-content/uploads/2023/10/testi02-2.png"
                              width={48}
                              alt=""
                            />
                          </div>
                          <div className="testimonial__author-designation">
                            <h6>Guy Hawkins</h6>
                            <span>Trade Master </span>
                          </div>
                        </div>
                        <div className="testimonial__quote">
                          <span>
                            <i className="fas fa-quote-right" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testimonial__item testimonial__item--style1">
                  <div className="testimonial__item-inner">
                    <div className="testimonial__item-content">
                      <p className="mb-0">
                        The above testimonial is about Martha Chumo, who taught
                        herself to code in one summer. This testimonial example
                        works because it allows prospective customers to see
                        themselves in Codeacademy’s current customer base.
                      </p>
                      <div className="testimonial__footer">
                        <div className="testimonial__author">
                          <div className="testimonial__author-thumb">
                            <img
                              decoding="async"
                              src="https://bitrader.thetork.com/wp-content/uploads/2023/10/testi03-2.png"
                              width={48}
                              alt=""
                            />
                          </div>
                          <div className="testimonial__author-designation">
                            <h6>Peter C. Harris</h6>
                            <span>Trade Master </span>
                          </div>
                        </div>
                        <div className="testimonial__quote">
                          <span>
                            <i className="fas fa-quote-right" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Eleven