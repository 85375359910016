import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const Purificationartical = () => {
  return (
    <>
      <Header page={true} />

      <main className="main-area fix mt-5">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
          <div>


            <section
              className="breadcrumb-area bg--cover "
              style={{
                backgroundImage:
                  "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
              }}
            >
              <div className="container">
                <div className="breadcrumb-area__content">
                  <h2>Purification of Earnings</h2>
                  <nav aria-label="breadcrumb" className="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a
                        property="item"
                        typeof="WebPage"
                        title="Go to Bitrader."
                        href="/"
                        className="home"
                      >
                        <span property="name">Home</span>
                      </a>
                      <meta property="position" content={1} />
                    </span>{" "}
                    &gt;{" "}
                    <span property="itemListElement" typeof="ListItem">
                      <span property="name" className="post post-teams current-item">
                        Purification of Earnings
                      </span>
                      {/* <meta
    property="url"
    content="https://bitrader.thetork.com/teams/albert-flores/"
  />
  <meta property="position" content={3} /> */}
                    </span>{" "}
                  </nav>
                </div>
                <div className="breadcrumb-area__shape">
                  <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
                    <img
                      src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
                      alt="Image"
                    />
                  </span>
                </div>
              </div>
            </section>
            <div className='p-5 mt-5 mb-5'>

              <div className='row'>


                <div className='col-lg-6 col-sm-12 col-md-6'>
<div className='p-3'>
                  <div
                    className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
                    data-id="2a404d7"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-size-default">
                        Purification of Earnings
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
                    data-id="77c0134"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >

                    <div className="elementor-widget-container ">

                      <p className="elementor-size-default">
                        Purification of Earnings: A Step Toward Ethical and Shariah-Compliant Investing
                      </p>
                      <p className="elementor-size-default">
                        In the journey toward ethical and faith-driven investing, the purification of impure earnings is a
                        cornerstone of maintaining Shariah compliance. For those committed to aligning their financial decisions
                        with Islamic values, purging income derived from non-compliant sources is not just a formality—it’s an
                        obligation and a spiritual act of devotion.
                      </p>
                      <p className="elementor-size-default">
                        At IslamicTijarat™, we go beyond standard financial services by offering advanced methodologies to help
                        you purify your investments with ease and accuracy.
                      </p>
                      <p className='banner__content-heading title fs-4'>
                        <span className='fw-bolder'>What is Purification in Investing?</span>

                      </p>
                      <p className="elementor-size-default">
                        Purification, or purging, involves identifying and removing any impure income—such as earnings derived
                        from interest or non-permissible activities—from your portfolio. This income, though incidental, is
                        considered non-compliant under Shariah law. By donating this amount to charity without deriving
                        personal benefit, you ensure that your wealth remains pure and aligned with Islamic principles.
                      </p>



                      <img src='/Images/screnner/6.png' className='ps-3' />


                      <p className='banner__content-heading title fs-4'>
                        <span className='fw-bolder'>How IslamicTijarat™ Simplifies Purification</span>

                      </p>

                      <p className="elementor-size-default">
                        We at IslamicTijarat™ are committed to providing a seamless and advanced system to help you manage
                        the purification of your earnings. Here’s how we make it simple and effective for you:  </p>

                        <p className='banner__content-heading title fs-6'>
                        <span className='fw-bolder'>1. Comprehensive Portfolio Analysis:</span>

                      </p>
      

<p className="elementor-size-default">
We analyze your investment portfolio in collaboration with esteemed Shariah scholars and
experts. This ensures that every detail is accounted for in calculating the purging amount.  </p>
<p className='banner__content-heading title fs-6'>
                        <span className='fw-bolder'>2. Accurate Purging Calculation:</span>

                      </p>
      

<p className="elementor-size-default">
Our advanced methodology, guided by the trusted TASIS framework, identifies the exact portion
of your income that requires purification.</p>

<p className='banner__content-heading title fs-6'>
                        <span className='fw-bolder'>3. Transparent Reporting:</span>

                      </p>
      

<p className="elementor-size-default">
You’ll receive clear and detailed reports about your purging liability, helping you take informed
steps to maintain the purity of your investments.</p>

<p className='banner__content-heading title fs-6'>
                        <span className='fw-bolder'>4. Empowering You to Give Back:</span>

                      </p>
      

<p className="elementor-size-default">
While we calculate your purging amount, the responsibility of donating it to a charitable
organization or individual of your choice lies with you. This ensures that you retain full control
over where your contributions go, provided you do not personally benefit from the donation. </p>



                    </div>
                  </div>
                  </div>


                </div>
                <div className='col-lg-6 col-sm-12 col-md-6 '>
                  <div className='p-3'>
                  <img src='/Images/screnner/6.png' className='' />
                  <div >
                    <p className='banner__content-heading title fs-4'>
                      <span className='fw-bolder'>Why Purification Matters</span>

                    </p>
                    <ul style={{ listStyleType: 'none', marginLeft: '20px', lineHeight: '40px' }}>
                      <li>1. Maintains Ethical Integrity: Purification is essential to ensure that your investments uphold the
                        moral and ethical values of Islam.</li>
                      <li>2. Spiritual Accountability: It allows you to cleanse your income of any non-compliant elements,
                        bringing peace of mind and spiritual contentment.</li>
                      <li>3. Strengthens Social Impact: By donating the impure portion to charity, you contribute to the
                        welfare of society without benefiting personally, fulfilling your duty toward humanity.</li>
                    </ul>
                  </div>

                  <div>
                  <p className='banner__content-heading title fs-4'>
                      <span className='fw-bolder'>What Sets IslamicTijarat™ Apart?</span>

                    </p>
                    <p className="elementor-size-default">
                    Our partnership with TASIS and adoption of cutting-edge Shariah screening methodologies make
IslamicTijarat™ a leader in ethical investing. We take pride in offering services that not only guide you
toward compliance but also reflect our unwavering commitment to purity, transparency, and precision.
                    </p>
                    <p className="elementor-size-default">
                    With IslamicTijarat™, you’re not just purifying your earnings—you’re taking a bold step toward
transforming your financial journey into an ethical and spiritually fulfilling one.
</p>

<p className='banner__content-heading title fs-4'>
                      <span className='fw-bolder'>Take the First Step Today</span>

                    </p>
                    <p className="elementor-size-default">
                    Purification is more than just a process; it’s a responsibility and a testament to your commitment to
faith-driven investing. Let IslamicTijarat™ be your trusted partner in achieving financial purity. Our
innovative tools and methodologies ensure that every step is simple, accurate, and meaningful.
</p>
<p>
Start your journey toward purified investments today and experience the difference with
IslamicTijarat™—where faith meets finance.
</p>

<img src='/Images/screnner/6.png' className='' />
                  </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Purificationartical