import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'


const ScreeningCriteria = () => {
  return (
    <>
    <Header page={true} />
    <main className="main-area fix mt-5">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
<div>


<section
  className="breadcrumb-area bg--cover "
  style={{
    backgroundImage:
      "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
  }}
>
  <div className="container">
    <div className="breadcrumb-area__content">
      <h2>Our Shariah Screening Criteria</h2>
      <nav aria-label="breadcrumb" className="breadcrumb">
        <span property="itemListElement" typeof="ListItem">
          <a
            property="item"
            typeof="WebPage"
            title="Go to Bitrader."
            href="/"
            className="home"
          >
            <span property="name">Home</span>
          </a>
          <meta property="position" content={1} />
        </span>{" "}
        &gt;{" "}
        <span property="itemListElement" typeof="ListItem">
          <span property="name" className="post post-teams current-item">
          Our Shariah Screening Criteria
          </span>
          {/* <meta
            property="url"
            content="https://bitrader.thetork.com/teams/albert-flores/"
          />
          <meta property="position" content={3} /> */}
        </span>{" "}
      </nav>
    </div>
    <div className="breadcrumb-area__shape">
      <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
        <img
          src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
          alt="Image"
        />
      </span>
    </div>
  </div>
</section>
<div className='p-5 mt-5 mb-5'>

<div className='row'>


    <div className='col-lg-7 col-sm-12 col-md-7'>

        <div
          className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
          data-id="2a404d7"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h2 className="elementor-size-default">
            Our Shariah Screening Criteria
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
          data-id="77c0134"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
            
          <div className="elementor-widget-container ">
            <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>IslamicTijarat™: Setting New Standards in Shariah-Compliant Investment</span>

            </p>
            <p className="elementor-size-default">
            At IslamicTijarat™, we are driven by a singular vision: to empower ethical investors with the most robust
and pure Shariah-compliant investment options. Our commitment to excellence has led us to adopt and
enhance the Shariah Screening Methodology developed by TASIS (Taqwaa Advisory and Shariah
Investment Solutions), renowned for its rigorous adherence to Shariah principles.
            </p>
            <p className="elementor-size-default">
            We have gone beyond standard practices to ensure that our screening process is not only thorough but
also tailored to meet the evolving needs of ethical investors. By combining the expertise of TASIS with
our deep understanding of Islamic financial values, IslamicTijarat™ delivers one of the world's most
comprehensive and stringent Shariah screening methodologies, offering unparalleled assurance and
purity for your investments.
            </p>


            <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>Our Commitment to Purity in Investments</span>

            </p>
            <p className="elementor-size-default">
            We deeply value the trust you place in us to help align your investments with Islamic values. That’s why
we go beyond simply following the rules—we passionately uphold both the precise guidelines and the
profound ethics of Shariah. Our meticulous screening process ensures every investment is not just
technically compliant but also resonates with the true essence and moral spirit of Islamic finance.
            </p>
          </div>
        </div>
      
 
    </div>
    <div className='col-lg-5 col-sm-12 col-md-5'>
    <img src='/Images/screnner/5.png' className='ps-3' />

    </div>
</div>
</div>

</div>
            </div>
            </main>
<Footer />
</>
  )
}

export default ScreeningCriteria