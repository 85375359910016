import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'
const ShariahCompliantInvesting = () => {
  return (
    <>
    <Header page={true} />

    <main className="main-area fix mt-5">
      <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
        <div>


          <section
            className="breadcrumb-area bg--cover "
            style={{
              backgroundImage:
                "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
            }}
          >
            <div className="container">
              <div className="breadcrumb-area__content">
                <h2>Shariah-Compliant Investing</h2>
                <nav aria-label="breadcrumb" className="breadcrumb">
                  <span property="itemListElement" typeof="ListItem">
                    <a
                      property="item"
                      typeof="WebPage"
                      title="Go to Bitrader."
                      href="/"
                      className="home"
                    >
                      <span property="name">Home</span>
                    </a>
                    <meta property="position" content={1} />
                  </span>{" "}
                  &gt;{" "}
                  <span property="itemListElement" typeof="ListItem">
                    <span property="name" className="post post-teams current-item">
                  Shariah-Compliant Investing
                    </span>
                    {/* <meta
  property="url"
  content="https://bitrader.thetork.com/teams/albert-flores/"
/>
<meta property="position" content={3} /> */}
                  </span>{" "}
                </nav>
              </div>
              <div className="breadcrumb-area__shape">
                <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
                  <img
                    src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
                    alt="Image"
                  />
                </span>
              </div>
            </div>
          </section>
          <div className='p-5 mt-5 mb-5'>

            <div className='row '>


              <div className='col-lg-6 col-sm-12 col-md-6'>
<div className='p-3'>
                <div
                  className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
                  data-id="2a404d7"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h3 className="elementor-size-default">
                    Why Choose Shariah-Compliant Investing?
                    </h3>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
                  data-id="77c0134"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >

                  <div className="elementor-widget-container ">

                    <p className="elementor-size-default">
                    Shariah-compliant investing ensures that your wealth grows in an ethical manner while avoiding involvement in prohibited financial activities. Both stocks and IPOs play a critical role in portfolio diversification for halal investments.
                    </p>

                    <p className='banner__content-heading title fs-5'>
                      <span className='fw-bolder'>   To simplify the screening process, platforms like IslamicTijarat™ provide:</span>

                    </p>
                 
                   
<p className="elementor-size-default">
Real-time Shariah compliance updates.  </p>
<p className="elementor-size-default">
Comprehensive business activity and financial ratio analysis.  </p>
                   

<p className="elementor-size-default">
Tools for purification calculations. 
</p>

<p className="elementor-size-default">
With IslamicTijarat™, you can confidently invest in halal stocks and IPOs listed on NSE and BSE while staying true to your Islamic values.
</p>


                  </div>
                </div>
                </div>

              </div>
              <div className='col-lg-6 col-sm-12 col-md-6'>
                <div className='p-3'>
                <div className='text-center'>
                <img src='/Images/Icone/investing.png' width={450} />
                </div>
           
                <div >
                  <p className='banner__content-heading title fs-5'>
                    <span className='fw-bolder'>Conclusion:</span>

                  </p>
                  <p className="elementor-size-default">
                  While the principles of Shariah compliance remain the same, the process of screening listed stocks differs significantly from IPOs due to the availability of data, timing of screening, and reliance on projections. By understanding these differences, you can make informed decisions and invest ethically.
                  </p>
                  <p className="elementor-size-default">
                  For more insights on Shariah-compliant investing, visit IslamicTijarat™, the trusted platform for ethical investments in India.                  </p>
                 
                </div>

                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </main>
    <Footer />
  </>
  )
}

export default ShariahCompliantInvesting