import React from 'react'
import { useNavigate } from "react-router-dom";

const Nine = () => {

  const navigate = useNavigate();
  return (
    <div
    className="elementor-element elementor-element-d104b27 e-flex e-con-boxed e-con e-parent"
    data-id="d104b27"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-71e826b e-con-full e-flex e-con e-child"
        data-id="71e826b"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-679aa5d e-con-full e-flex e-con e-child"
          data-id="679aa5d"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-6e4ce1b elementor-widget elementor-widget-tg-heading"
            data-id="6e4ce1b"
            data-element_type="widget"
            data-widget_type="tg-heading.default"
          >
            <div className="elementor-widget-container">
              <div className="section-title">
                <h2 className="title">
                  <span>Articles </span> For Pro Traders
                </h2>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-bc9cb16 elementor-widget elementor-widget-heading"
            data-id="bc9cb16"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <p className="elementor-heading-title elementor-size-default">
                Hey there pro traders, check out these articles with tips to take
                your trading game to the next level!
              </p>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-1734f67 e-con-full e-flex e-con e-child"
          data-id="1734f67"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-24a113d elementor-widget elementor-widget-html"
            data-id="24a113d"
            data-element_type="widget"
            data-widget_type="html.default"
          >
            <div className="elementor-widget-container">
              <div className="section-header__action">
                <div className="swiper-nav swiper-nav--style1 justify-content-start justify-content-md-end">
                  <button className="swiper-nav__btn blog__slider-prev">
                    <i className="fas fa-angle-left" />
                  </button>
                  <button className="swiper-nav__btn blog__slider-next active">
                    <i className="fas fa-angle-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-4e7156b elementor-widget elementor-widget-blogpost"
        data-id="4e7156b"
        data-element_type="widget"
        data-widget_type="blogpost.default"
      >
        <div className="elementor-widget-container">
          <div
            className="blog__wrapper"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog__slider swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="blog__item home-blog-post blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={710}
                          height={480}
                          src="/Images/Articals/purify.jpg"
                          className="attachment-65 size-65 wp-post-image"
                          alt=""
                          full=""
                          srcSet="/Images/Articals/purify.jpg 710w, /Images/Articals/purify.jpg 300w"
                          sizes="(max-width: 710px) 100vw, 710px"
                        />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <a
                            href="/Purification"
                            className="blog__meta-tag blog__meta-tag--style1"
                            onClick={()=> navigate('/Purification')} 
                          >
                            Dividend stocks
                          </a>
                        </div>
                        <h5 className={10}  onClick={()=> navigate('/Purification')}>
                          <a href="/Purification" >
                          Purification of Earnings
                          </a>
                        </h5>
                        <p className="mb-15">
                        In the journey toward ethical and faith-driven investing, the purification of impure earnings is a
                        cornerstone of maintaining Shariah compliance.
                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              decoding="async"
                              src="https://secure.gravatar.com/avatar/b27b58fd81b5df40078fab7978abb73e?s=40&d=mm&r=g"
                              alt="admin"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">admin</h6>
                            {/* <span>D 6, 2023</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog__item home-blog-post blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={710}
                          height={480}
                          src="/Images/Articals/purify.jpg"
                          className="attachment-63 size-63 wp-post-image"
                          alt=""
                          full=""
                          srcSet="/Images/Articals/screen.jpg 710w, /Images/Articals/screen.jpg 300w"
                          sizes="(max-width: 710px) 100vw, 710px"
                        />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <a
                            href="/ScreeningCriteriapage"
                            className="blog__meta-tag blog__meta-tag--style1"
                            onClick={()=>navigate('/ScreeningCriteriapage')}
                          >
                            Dividend stocks
                          </a>
                        </div>
                        <h5 className={10}>
                          <a href="/ScreeningCriteriapage" onClick={()=>navigate('/ScreeningCriteriapage')}>
                          Our Shariah Screening Criteria
                          </a>
                        </h5>
                        <p className="mb-15">
                        IslamicTijarat™: Setting New Standards in Shariah-Compliant Investment
                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              decoding="async"
                              src="https://secure.gravatar.com/avatar/b27b58fd81b5df40078fab7978abb73e?s=40&d=mm&r=g"
                              alt="admin"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">admin</h6>
                            {/* <span>October 6, 2023</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog__item home-blog-post blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={710}
                          height={480}
                          src="/Images/Articals/sc.jpg"
                          className="attachment-61 size-61 wp-post-image"
                          alt=""
                          full=""
                          srcSet="/Images/Articals/sc.jpg 710w, /Images/Articals/sc.jpg 300w"
                          sizes="(max-width: 710px) 100vw, 710px"
                        />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <a
                            href="/ShariahCompliantInvesting"
                            onClick={()=>{navigate('/ShariahCompliantInvesting')}}
                            className="blog__meta-tag blog__meta-tag--style1"
                          >
                            dividend stocks
                          </a>
                        </div>
                        <h5 className={10}>
                          <a href="/ShariahCompliantInvesting" onClick={()=>{navigate('/ShariahCompliantInvesting')}}>
                          Shariah-Compliant Investing
                          </a>
                        </h5>
                        <p className="mb-15">
                        Shariah-compliant investing ensures that your wealth grows in an ethical manner while avoiding involvement in prohibited financial activities.

                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              decoding="async"
                              src="https://secure.gravatar.com/avatar/b27b58fd81b5df40078fab7978abb73e?s=40&d=mm&r=g"
                              alt="admin"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">admin</h6>
                            {/* <span>October 6, 2023</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-4677d85 e-con-full e-flex e-con e-child"
        data-id="4677d85"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-f3a0bcc elementor-widget elementor-widget-tg-btn"
          data-id="f3a0bcc"
          data-element_type="widget"
          data-widget_type="tg-btn.default"
        >
          {/* <div className="elementor-widget-container">
            <div className="myclass1">
              <a
                href="https://bitrader.thetork.com/blog/"
                target="_self"
                rel="nofollow"
                className="trk-btn trk-btn--border trk-btn--primary"
              >
                View more{" "}
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="elementor-element elementor-element-b7ecc40 elementor-widget__width-inherit elementor-absolute h-100 elementor-widget elementor-widget-bg-shapes"
        data-id="b7ecc40"
        data-element_type="widget"
        data-settings='{"_position":"absolute"}'
        data-widget_type="bg-shapes.default"
      >
        <div className="elementor-widget-container">
          <div className="blog__shape">
            <span className="blog__shape-item blog__shape-item--1">
              <span />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Nine