import React, { useEffect, useState } from "react";

const Banner = () => {


  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-bs-theme'));

  useEffect(() => {
    // Function to check and update the theme
    const checkThemeChange = () => {
      const newTheme = document.documentElement.getAttribute('data-bs-theme');
      if (newTheme !== theme) {
        setTheme(newTheme);
      }
    };

    // Observe changes to the `data-bs-theme` attribute
    const mutationObserver = new MutationObserver(checkThemeChange);

    mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme'],
    });

    // Cleanup the observer on component unmount
    return () => {
      mutationObserver.disconnect();
    };
  }, [theme]); 
  return (
    <div
      className="elementor-element elementor-element-25a533a e-con-full e-flex e-con e-parent"
      data-id="25a533a"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-2ac0b36 elementor-widget elementor-widget-hero-banner"
        data-id="2ac0b36"
        data-element_type="widget"
        data-widget_type="hero-banner.default"
      >
        <div className="elementor-widget-container">
          <section className="banner banner--style1 tg-section">
            <div className="banner__bg">
              <div className="banner__bg-element">
                <img
                  decoding="async"
                  src="https://bitrader.thetork.com/wp-content/uploads/2023/10/banner_bg.png"
                  alt="section-bg-element"
                  className="dark d-none d-lg-block"
                />
                <span className="bg-color d-lg-none" />
              </div>
            </div>
            <div className="container">
              <div className="banner__wrapper">
                <div className="row gy-5 gx-4">
                  <div className="col-lg-6 col-md-7" style={{ textAlign: 'left' }}>
                    <div
                      className="banner__content tg-content"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      <div className="banner__content-coin">
                        <img
                          decoding="async"
                          src="https://bitrader.thetork.com/wp-content/uploads/2023/10/banner_coin.png"
                          alt="Coin icon"
                        />
                      </div>
                      <h1 className="banner__content-heading title">
                        Islamic<span>Tijarat<sup style={{ fontSize: "16px", verticalAlign: "super", marginLeft: "2px" }}>TM</sup>
                        </span>
                      </h1>

                      <p className="banner__content-moto">
                        At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.{" "}
                      </p>
                      <div className="banner__btn-group btn-group">
                        <a
                          href=""
                          target="_self"
                          rel="nofollow"
                          className="trk-btn trk-btn--primary trk-btn--arrow"
                        >
                          Get Started{" "}
                          <span>
                            <i className="fas fa-arrow-right" />
                          </span>
                        </a>
                        <a
                          href="https://www.youtube.com/watch?v=6mkoGSqTqFI"
                          className="trk-btn trk-btn--outline22 btngsc"
                          data-fslightbox=""
                        >
                          <span className="style1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_1397_814)">
                                <path
                                  d="M10.5547 7.03647C9.89015 6.59343 9 7.06982 9 7.86852V16.1315C9 16.9302 9.89015 17.4066 10.5547 16.9635L16.7519 12.8321C17.3457 12.4362 17.3457 11.5638 16.7519 11.1679L10.5547 7.03647Z"
                                  stroke="#0A4FD5"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <rect
                                x="-0.75"
                                y="0.75"
                                width="22.5"
                                height="22.5"
                                rx="11.25"
                                transform="matrix(-1 0 0 1 22.5 0)"
                                stroke="#0A4FD5"
                                strokeWidth="1.5"
                              />
                              <defs>
                                <clipPath id="clip0_1397_814">
                                  <rect width={24} height={24} fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>{" "}
                          Watch Video{" "}
                        </a>
                      </div>
                      <div className="banner__content-social float-left">
                        <p>Follow Us</p>
                        <ul className="social">
                          <li className="social__item">
                            <a
                              href="https://www.facebook.com/islamictijarat"
                              target='blank'
                              className="social__link social__link--style1"
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fab-facebook-f"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="social__item">
                            <a
                              href="https://x.com/islamictijarat"
                              className="social__link social__link--style1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                                
                              <img src={theme == 'dark' ? "/Images/Icone/twitterlight.png":"/Images/Icone/twitter.png"} width={18} />
                            </a>
                          </li>

                          {/* WhatsApp Icon */}



                          {/* Telegram Icon */}
                          <li className="social__item">
                            <a
                              href="https://t.me/islamictijarat"
                              className="social__link social__link--style1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img  src={theme == 'dark' ? "/Images/Icone/telegramlight.png":"/Images/Icone/telegram.png"} width={23} />
                            </a>
                          </li>
                          <li className="social__item">
                            <a
                              href="https://www.instagram.com/islamictijarat/"
                              target='blank'
                              className="social__link social__link--style1"
                            >
                              <img src={theme == 'dark' ? "/Images/Icone/instagramlight.png":"/Images/Icone/instagram.png"}  width={18} />
                            </a>
                          </li>
                          <li className="social__item">
                            <a
                              href="https://www.linkedin.com/in/islamictijarat"
                              target='blank'
                              className="social__link social__link--style1"
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fab-linkedin-in"
                                viewBox="0 0 448 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="social__item">
                            <a
                              href="https://www.youtube.com/islamictijarat"
                              target='blank'
                              className="social__link social__link--style1"
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fab-youtube"
                                target='blank'
                                viewBox="0 0 576 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="social__item">
                            <a
                              href="https://www.whatsapp.com/channel/0029VakHKaFJENy4EzxloA13"
                              className="social__link social__link--style1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={theme == 'dark' ? "/Images/Icone/whatsapplight.png":"/Images/Icone/whatsapp.png"}  width={20} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    <div
                      className="banner__thumb"
                      data-aos="fade-left"
                      data-aos-duration={1000}
                    >
                      <img
                        decoding="async"
                        src="/Images/banner4.webp"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner__shape">
              <span className="banner__shape-item banner__shape-item--1">
                <img
                  decoding="async"
                  src="https://bitrader.thetork.com/wp-content/uploads/2023/10/4.png"
                  width={43}
                  alt="shape icon"
                />
              </span>
            </div>
          </section>
          {/* banner-area-end */}
        </div>
      </div>
    </div>

  )
}

export default Banner