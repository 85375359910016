import React from 'react'

const Four = () => {
  return (
<div
  className="elementor-element elementor-element-c1c4aaf e-con-full e-flex e-con e-parent"
  data-id="c1c4aaf"
  data-element_type="container"
>
  <div
    className="elementor-element elementor-element-94c7ebe elementor-widget elementor-widget-offer-tab"
    data-id="94c7ebe"
    data-element_type="widget"
    data-widget_type="offer-tab.default"
  >
    <div className="elementor-widget-container">
      <section className="feature feature--style1 padding-bottom padding-top bg-color">
        <div className="container">
          <div className="feature__wrapper">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-md-6 col-lg-5">
                <div
                  className="feature__content"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="feature__content-inner text-start">
                    <div className="section-header">
                      <h3 className="mb-15 mt-minus-5">
                      Difference Between
                        <span className='ms-1 me-1' style={{ color: "var(--secondary-color)" }}>
                         Shariah-Compliant Screening
                        </span>
                          of Stocks and IPOs
                        We offer
                      </h3>
                      <p className="mb-0">
                      Shariah-compliant investing is an essential approach for individuals who want their investments to align with Islamic financial principles. This involves screening stocks and Initial Public Offerings (IPOs) to ensure they meet the ethical guidelines outlined in Islamic law. However, while the purpose remains the same, the process of Shariah screening for stocks differs significantly from that of IPOs.
                      </p>

                      <p className="mt-3">
                      In this blog, we will explain the differences between these two processes, their unique challenges, and how you can make ethical investment decisions with tools like IslamicTijarat™, a dedicated Shariah-compliant stock screening platform.                      </p>
                    </div>
                    {/* <div className="feature__nav">
                      <div
                        className="nav nav--feature flex-column nav-pills"
                        id="feat-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <div
                          className="nav-link active"
                          id="tg-tab-0"
                          data-bs-toggle="pill"
                          data-bs-target="#tg-id-0"
                          role="tab"
                          aria-controls="tg-id-0"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Lending money for investment of your new
                                  projects
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link "
                          id="tg-tab-1"
                          data-bs-toggle="pill"
                          data-bs-target="#tg-id-1"
                          role="tab"
                          aria-controls="tg-id-1"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Lending money for investment of your new
                                  projects
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link "
                          id="tg-tab-2"
                          data-bs-toggle="pill"
                          data-bs-target="#tg-id-2"
                          role="tab"
                          aria-controls="tg-id-2"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Mobile payment is more flexible and easy for
                                  all investors
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link "
                          id="tg-tab-3"
                          data-bs-toggle="pill"
                          data-bs-target="#tg-id-3"
                          role="tab"
                          aria-controls="tg-id-3"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  all transaction is kept free for the member of
                                  pro traders
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div
                  className="feature__thumb pt-0 pt-md-0"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="feature__thumb-inner">
                    <div className="tab-content" id="feat-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="tg-id-0"
                        role="tabpanel"
                        aria-labelledby="tg-tab-0"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                        <img
  decoding="async"
  src="Images/Icone/new2.png"
  alt="Feature image"
  style={{
    transform: "scaleX(-1)",
    marginTop:'-100px', // Flips the image horizontally
  }}
/>
                          {/* <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img
                                decoding="async"
                                src="https://bitrader.thetork.com/wp-content/uploads/2023/10/percent01.png"
                                width={80}
                                alt="Feature image"
                              />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div> */}
                          {/* <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">10M</h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade "
                        id="tg-id-1"
                        role="tabpanel"
                        aria-labelledby="tg-tab-1"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            decoding="async"
                            src="https://bitrader.thetork.com/wp-content/uploads/2023/10/features02.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img
                                decoding="async"
                                src="https://bitrader.thetork.com/wp-content/uploads/2023/10/percent02.png"
                                width={80}
                                alt="Feature image"
                              />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">18M</h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade "
                        id="tg-id-2"
                        role="tabpanel"
                        aria-labelledby="tg-tab-2"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            decoding="async"
                            src="https://bitrader.thetork.com/wp-content/uploads/2023/10/features01.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img
                                decoding="async"
                                src="https://bitrader.thetork.com/wp-content/uploads/2023/10/percent03.png"
                                width={80}
                                alt="Feature image"
                              />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">30M</h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade "
                        id="tg-id-3"
                        role="tabpanel"
                        aria-labelledby="tg-tab-3"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            decoding="async"
                            src="https://bitrader.thetork.com/wp-content/uploads/2023/10/features02.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img
                                decoding="async"
                                src="https://bitrader.thetork.com/wp-content/uploads/2023/10/percent04.png"
                                width={80}
                                alt="Feature image"
                              />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">28M</h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature__shape">
          <span className="feature__shape-item feature__shape-item--1">
            <img
              decoding="async"
              src="https://bitrader.thetork.com/wp-content/uploads/2023/10/features_shape.png"
              width={70}
              alt="shape-icon"
            />
          </span>
          <span className="feature__shape-item feature__shape-item--2">
            {" "}
            <span />{" "}
          </span>
        </div>
      </section>
    </div>
  </div>
</div>

  )
}

export default Four