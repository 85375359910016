import React from 'react'

const Seven = () => {
  return (
<div
  className="elementor-element elementor-element-c12d652 e-flex e-con-boxed e-con e-parent"
  data-id="c12d652"
  data-element_type="container"
>
  <div className="e-con-inner">
    <div
      className="elementor-element elementor-element-f0c4ab8 elementor-widget__width-inherit elementor-absolute h-100 elementor-widget elementor-widget-bg-shapes"
      data-id="f0c4ab8"
      data-element_type="widget"
      data-settings='{"_position":"absolute"}'
      data-widget_type="bg-shapes.default"
    >
      <div className="elementor-widget-container">
        <div className="pricing__shape">
          <span className="pricing__shape-item pricing__shape-item--1">
            <span />
          </span>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-6edf80b elementor-widget__width-auto elementor-absolute upAndDown_animation elementor-widget elementor-widget-image"
      data-id="6edf80b"
      data-element_type="widget"
      data-settings='{"_position":"absolute"}'
      data-widget_type="image.default"
    >
      <div className="elementor-widget-container">
        <img
          decoding="async"
          width={106}
          height={106}
          src="https://bitrader.thetork.com/wp-content/uploads/2023/10/shape01-2.png"
          className="attachment-full size-full wp-image-238"
          alt=""
        />
      </div>
    </div>
    <div
      className="elementor-element elementor-element-703693d e-flex e-con-boxed e-con e-child"
      data-id="703693d"
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-e357adf elementor-widget elementor-widget-tg-heading"
          data-id="e357adf"
          data-element_type="widget"
          data-widget_type="tg-heading.default"
        >
          <div className="elementor-widget-container">
            <div className="section-title">
              <h2 className="title">
                Our <span>Pricings </span>Plan
              </h2>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-73c2afc elementor-widget elementor-widget-heading"
          data-id="73c2afc"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <p className="elementor-heading-title elementor-size-default">
              We offer the best pricings around - from installations to repairs,
              maintenance, and more!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-ba32f05 e-con-full e-flex e-con e-child"
      data-id="ba32f05"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-6cb1520 e-con-full e-flex e-con e-child"
        data-id="6cb1520"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-fb07b78 elementor-widget elementor-widget-tg-pricing"
          data-id="fb07b78"
          data-element_type="widget"
          data-widget_type="tg-pricing.default"
        >
          <div className="elementor-widget-container">
            <div
              className="pricing__item"
              data-aos="fade-right"
              data-aos-duration={1000}
            >
              <div className="pricing__item-inner default">
                <div className="pricing__item-content">
                  {/* pricing top part */}
                  <div className="pricing__item-top">
                    <h6 className="mb-15">Basic</h6>
                    <h3 className="mb-25">
                      $99/<span>Monthly</span>{" "}
                    </h3>
                  </div>
                  {/* pricing middle part */}
                  <div className="pricing__item-middle">
                    <ul className="pricing__list list-wrap">
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                 src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Weekly online meeting
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                    src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Unlimited learning access
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                       src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        24/7 technical support
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                    src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Personal training
                      </li>
                    </ul>
                  </div>
                  {/* pricing bottom part */}
                  <div className="pricing__item-bottom">
                    <a
                      href="#"
                      target="_blank"
                      rel="nofollow"
                      className="trk-btn trk-btn--outline"
                    >
                      Choose Plan{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-d549e45 e-con-full e-flex e-con e-child"
        data-id="d549e45"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-cac9be2 elementor-widget elementor-widget-tg-pricing"
          data-id="cac9be2"
          data-element_type="widget"
          data-widget_type="tg-pricing.default"
        >
          <div className="elementor-widget-container">
            <div
              className="pricing__item"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              <div className="pricing__item-inner active">
                <div className="pricing__item-content">
                  {/* pricing top part */}
                  <div className="pricing__item-top">
                    <h6 className="mb-15">standard</h6>
                    <h3 className="mb-25">
                      $149/<span>Monthly</span>{" "}
                    </h3>
                  </div>
                  {/* pricing middle part */}
                  <div className="pricing__item-middle">
                    <ul className="pricing__list list-wrap">
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                            src="Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Weekly online meeting
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                  src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Unlimited learning access
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                        src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        24/7 technical support
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                   src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Personal training
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                       src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Business analysis
                      </li>
                    </ul>
                  </div>
                  {/* pricing bottom part */}
                  <div className="pricing__item-bottom">
                    <a
                      href="#"
                      target="_blank"
                      rel="nofollow"
                      className="trk-btn trk-btn--outline"
                    >
                      Choose Plan{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-33487f9 e-con-full e-flex e-con e-child"
        data-id="33487f9"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-4421ff9 elementor-widget elementor-widget-tg-pricing"
          data-id="4421ff9"
          data-element_type="widget"
          data-widget_type="tg-pricing.default"
        >
          <div className="elementor-widget-container">
            <div
              className="pricing__item"
              data-aos="fade-left"
              data-aos-duration={1000}
            >
              <div className="pricing__item-inner default">
                <div className="pricing__item-content">
                  {/* pricing top part */}
                  <div className="pricing__item-top">
                    <h6 className="mb-15">premium</h6>
                    <h3 className="mb-25">
                      $199/<span>Monthly</span>{" "}
                    </h3>
                  </div>
                  {/* pricing middle part */}
                  <div className="pricing__item-middle">
                    <ul className="pricing__list list-wrap">
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                        src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Weekly online meeting
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                                   src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Unlimited learning access
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                              src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        24/7 technical support
                      </li>
                      <li className="pricing__list-item">
                        <span>
                          <img
                            decoding="async"
                          src="/Images/Icone/tick.png"
                            width={24}
                            alt="Icon"
                          />
                        </span>
                        Personal training
                      </li>
                    </ul>
                  </div>
                  {/* pricing bottom part */}
                  <div className="pricing__item-bottom">
                    <a
                      href="#"
                      target="_blank"
                      rel="nofollow"
                      className="trk-btn trk-btn--outline "
                    >
                      Choose Plan{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Seven