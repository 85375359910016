import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'    

const ScreeningCriteria = () => {
  return (
    <>
      <Header page={true} />

      <main className="main-area fix mt-5">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
          <div>


            <section
              className="breadcrumb-area bg--cover "
              style={{
                backgroundImage:
                  "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
              }}
            >
              <div className="container">
                <div className="breadcrumb-area__content">
                  <h2>Our Shariah Screening Criteria</h2>
                  <nav aria-label="breadcrumb" className="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a
                        property="item"
                        typeof="WebPage"
                        title="Go to Bitrader."
                        href="/"
                        className="home"
                      >
                        <span property="name">Home</span>
                      </a>
                      <meta property="position" content={1} />
                    </span>{" "}
                    &gt;{" "}
                    <span property="itemListElement" typeof="ListItem">
                      <span property="name" className="post post-teams current-item">
                      Our Shariah Screening Criteria
                      </span>
                      {/* <meta
    property="url"
    content="https://bitrader.thetork.com/teams/albert-flores/"
  />
  <meta property="position" content={3} /> */}
                    </span>{" "}
                  </nav>
                </div>
                <div className="breadcrumb-area__shape">
                  <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
                    <img
                      src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
                      alt="Image"
                    />
                  </span>
                </div>
              </div>
            </section>
            <div className='p-5 mt-5 mb-5'>

              <div className='row'>

                <div className='col-lg-6 col-sm-12 col-md-6 '>
<div className='p-3'>
                  <div
                    className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
                    data-id="2a404d7"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-size-default">
                      Our Shariah Screening Criteria
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
                    data-id="77c0134"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >

                    <div className="elementor-widget-container ">

                      <p className="elementor-size-default">
                      IslamicTijarat™: Setting New Standards in Shariah-Compliant Investment
                                            </p>
                      <p className="elementor-size-default">
                      At IslamicTijarat™, we are driven by a singular vision: to empower ethical investors with the most robust
and pure Shariah-compliant investment options. Our commitment to excellence has led us to adopt and
enhance the Shariah Screening Methodology developed by TASIS (Taqwaa Advisory and Shariah
Investment Solutions), renowned for its rigorous adherence to Shariah principles.
                      </p>
                      <p className="elementor-size-default">
                      We have gone beyond standard practices to ensure that our screening process is not only thorough but
also tailored to meet the evolving needs of ethical investors. By combining the expertise of TASIS with
our deep understanding of Islamic financial values, IslamicTijarat™ delivers one of the world's most
comprehensive and stringent Shariah screening methodologies, offering unparalleled assurance and
purity for your investments.
                      </p>
                      <p className='banner__content-heading title fs-4'>
                        <span className='fw-bolder'>Our Commitment to Purity in Investments</span>

                      </p>
                      <p className="elementor-size-default">
                      We deeply value the trust you place in us to help align your investments with Islamic values. That’s why
we go beyond simply following the rules—we passionately uphold both the precise guidelines and the
profound ethics of Shariah. Our meticulous screening process ensures every investment is not just
technically compliant but also resonates with the true essence and moral spirit of Islamic finance.
                      </p>



                      <img src='/Images/screnner/6.png' className='ps-3' />


                      <p className='banner__content-heading title fs-4'>
                        <span className='fw-bolder'>➢ Business Screening: </span>

                      </p>

                      <p className="elementor-size-default">
                      
                      We strictly exclude investments in sectors deemed impermissible under Shariah, as outlined below:
                       </p>

  <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>1.Prohibited Sectors</span>

        </p>
        <p className="elementor-size-default">
            These industries are inherently impermissible under Shariah law and include:
        </p>
        <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '40px' }}>
            <li>Conventional (interest-based) banking and financial services.</li>
            <li>Conventional (interest-based) insurance.</li>
            <li>Brokerage of interest-based financial products.</li>
            <li>Fund-based financial services.</li>
            <li>Manufacture, distribution, and sale of alcoholic beverages or narcotics.</li>
            <li>Processing, distribution, and sale of pork and pork-related products.</li>
            <li>Gambling and tobacco-related industries.</li>
        </ul>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>2.Conditionally Prohibited Sectors</span>

        </p>

        <p className="elementor-size-default">
            In the Indian context, certain industries are considered impermissible unless certified as Shariahcompliant by a reliable Shariah compliance monitoring authority. These include:
        </p>



        <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '40px' }}>
            <li>Meat processing and marketing industries.</li>
            <li>Sugar manufacturing units.</li>
            <li>Media broadcasting and entertainment industries.</li>
            <li>Diversified companies operating across multiple sectors.</li>
        </ul>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>3. Companies with Partial Prohibited Activities (Secondary Business)</span>

        </p>
        <p className="elementor-size-default">
            On a case-by-case basis, we exclude companies whose main business does not fall under the
            impermissible sectors but have some level of involvement in prohibited activities—either directly or
            through subsidiaries.
        </p>
        <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>Permissible Investments</span>

        </p>
        <p className="elementor-size-default">
            Investments are permitted in companies that do not operate in the sectors or activities outlined above,
            ensuring alignment with Shariah principles.
        </p>







                    </div>
                  </div>

                  </div>
                </div>
                <div className='col-lg-6 col-sm-12 col-md-6 '>
                    <div className='p-3'>
                  <img src='/Images/screnner/6.png' className='' />
             
                  <div className="elementor-widget-container ">
                  <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>  ➢ Financial Screening Parameters</span>

        </p>
                
               <p className="elementor-size-default">
               In addition to the nature of the business, companies must meet stringent financial criteria to qualify as
Shariah-compliant. These include:
               </p>

               <p className='banner__content-heading title fs-5'>
               <span className='fw-bolder'>1. Total Interest-Based Debt</span>
   
               </p>
               <p className="elementor-size-default">
               The company’s total interest-based debt should not exceed 25% of its Total Assets.
               </p>
               <p className='banner__content-heading title fs-5'>
               <span className='fw-bolder'>2. Interest Income</span>
   
               </p>
               <p className="elementor-size-default">
               The interest income from all sources should not exceed 2.5% of its Total Income.
               </p>
               <p className='banner__content-heading title fs-5'>
               <span className='fw-bolder'>3. Receivables and Cash Holdings</span>
   
               </p>
               <p className="elementor-size-default">
               The company’s receivables, cash, and bank balance should not exceed 90% of its Total
Assets.
               </p>
               <p className='banner__content-heading title fs-5'>
               <span className='fw-bolder'>4. Screening Basis</span>
   
               </p>
               <p className="elementor-size-default">
               Financial assessments are conducted using Consolidated, Audited, Annual Financial
Results.
               </p>
   
   


               <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'> Commitment to Continuous Monitoring</span>

        </p>

               <div className="elementor-widget-container ">
        <p className="elementor-size-default">
        We understand the dynamic nature of financial markets and the evolving needs of ethical investors.
        Therefore, the Shariah Board periodically reviews and updates the screening norms to minimize interestbased transactions while ensuring the financial viability of portfolios.
        </p>
        <p className="elementor-size-default">
        IslamicTijarat™ ensures that any updates to these criteria are promptly communicated to our users and
stakeholders, maintaining complete transparency and trust.
        </p>
        <p className="elementor-size-default">
        By adopting the trusted methodology, we provide our users with the assurance of investing in ethically
        responsible and Shariah-compliant avenues.
        </p>
      </div>

      <p className='banner__content-heading title fs-4'>
            <span className='fw-bolder'>Why IslamicTijarat™?</span>

        </p>



        <div className="elementor-widget-container ">
        <p className='banner__content-heading title fs-5'>
        <span className='fw-bolder'>Unmatched Purity</span>

        </p>
        <p className="elementor-size-default">
        Our screening process is one of the most stringent in the world, ensuring the
        highest level of compliance with Islamic financial principles.
        </p>
        <p className='banner__content-heading title fs-5'>
        <span className='fw-bolder'> Expert Collaboration</span>

        </p>

    
        <p className="elementor-size-default">
        By adopting TASIS's methodology and incorporating additional
        enhancements, we ensure a seamless blend of trust, expertise, and precision.
        </p>


        <p className='banner__content-heading title fs-5'>
        <span className='fw-bolder'>Transparency</span>

        </p>
        <p className="elementor-size-default">
        We keep our users informed about every update to our screening criteria, so you
can invest with confidence and peace of mind.
        </p>
        <p className="elementor-size-default">
        At IslamicTijarat™, we strive tirelessly to deliver the best in ethical investing, helping you align your
financial goals with your faith. Trust us to be your partner in achieving purity and success in your
investments.
        </p>
      </div>
      <img src='/Images/screnner/6.png' className='' />
             </div>

                </div>

             
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default ScreeningCriteria