import React from 'react'
import TradingViewTickerTape from '../widget/TradingViewTickerTape'

const Partner = () => {
  return (
    <div
  className="elementor-element elementor-element-ee44b74 e-con-full e-flex e-con e-parent"
  data-id="ee44b74"
  data-element_type="container"
>
  <div
    className="elementor-element elementor-element-5433ca4 elementor-widget elementor-widget-brand"
    data-id="5433ca4"
    data-element_type="widget"
    data-widget_type="brand.default"
  >
    <div className="elementor-widget-container">
      <div className="partner partner--gradient tg-section">
        <div className="container">
          <div className="partner__wrapper">
            <div className="partner__slider swiper">
            <TradingViewTickerTape />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Partner