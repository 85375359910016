import React from 'react'

const Five = () => {
  return (
    <div
  className="elementor-element elementor-element-014671b e-flex e-con-boxed e-con e-parent"
  data-id="014671b"
  data-element_type="container"
>
  <div className="">
    <div
      className="elementor-element elementor-element-f98f54e e-flex e-con-boxed e-con e-child"
      data-id="f98f54e"
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-d7c75de elementor-widget elementor-widget-tg-heading"
          data-id="d7c75de"
          data-element_type="widget"
          data-widget_type="tg-heading.default"
        >
          <div className="elementor-widget-container">
            <div className="section-title">
              <h2 className="title">
                <span>Services</span> We offer
              </h2>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-cc01eca elementor-widget elementor-widget-heading"
          data-id="cc01eca"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
         <div className="elementor-widget-container">
            <p className="elementor-heading-title elementor-size-default">
              We offer the best services around - from installations to repairs,
              maintenance, and more!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-94c77b0 elementor-widget elementor-widget-tg-servicesBox"
      data-id="94c77b0"
      data-element_type="widget"
      data-widget_type="tg-servicesBox.default"
    >
      <div className="elementor-widget-container">
        <div className="row g-4 align-items-center">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              className="service__item service__item--" 
              data-aos="fade-up"
              data-aos-duration={200}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner w-100 d-flex justify-content-center">
                    <img
                      decoding="async"
                      src="/Images/Icone/screnner.png"
                      width={150}
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-15">
                    <a
                      className="stretched-link"
                      href="#"
                    >
                  Stock Screener
                    </a>
                  </h5>
                  <p className="mb-0">
                  Discover stocks with precision using advanced filters to assess Shariah compliance and ethical alignment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              className="service__item service__item--"
              data-aos="fade-up"
              data-aos-duration={300}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner w-100 d-flex justify-content-center" >
                    <img
                      decoding="async"
                      src="/Images/Icone/ipo.png"
                  
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-15">
                    <a
                      className="stretched-link"
                      href="#"
                    >
                      IPO Screener
                    </a>
                  </h5>
                  <p className="mb-0">
                  An IPO screener in Islamic tijarat identifies companies offering Shariah-compliant IPOs by ensuring adherence to Islamic law.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              className="service__item service__item--"
              data-aos="fade-up"
              data-aos-duration={400}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner w-100 d-flex justify-content-center"  >
                    <img
                      decoding="async"
                      src="/Images/Icone/purification.png"
                      width={90}
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-0">
                    <a
                      className="stretched-link"
                      href="#"
                    >
                      Purification Calculator
                    </a>
                  </h5>
                  <p className="mb-0">
                  Did you know that investing in halal stocks may still generate non-compliant income. Start calculating your purification amount.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              className="service__item service__item--"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner w-100 d-flex justify-content-center">
                    <img
                      decoding="async"
                      src="/Images/Icone/zikat.png"
                      width={160}
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-15">
                    <a
                      className="stretched-link"
                      href="#"
                    >
                      Zakat Calculator
                    </a>
                  </h5>
                  <p className="mb-0">
                  Calculate your zakat obligations easily with our user-friendly calculator, ensuring compliance with Islamic principles.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-6 col-md-6 col-lg-4">
            <div
              className="service__item service__item--"
              data-aos="fade-up"
              data-aos-duration={1200}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner">
                    <img
                      decoding="async"
                      src="https://bitrader.thetork.com/wp-content/uploads/2023/10/services05.png"
                      width={48}
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-15">
                    <a
                      className="stretched-link"
                      href="https://bitrader.thetork.com/services/hr-consulting/"
                    >
                      HR consulting
                    </a>
                  </h5>
                  <p className="mb-0">
                    Hey guys, just a quick update on exchange orders. There have
                    been some changes currency!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div
              className="service__item service__item--"
              data-aos="fade-up"
              data-aos-duration={1300}
            >
              <div className="service__item-inner text-center">
                <div className="service__thumb mb-30">
                  <div className="service__thumb-inner">
                    <img
                      decoding="async"
                      src="https://bitrader.thetork.com/wp-content/uploads/2023/10/services06.png"
                      width={48}
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="service__content">
                  <h5 className="mb-15">
                    <a
                      className="stretched-link"
                      href="https://bitrader.thetork.com/services/marketing-consulting/"
                    >
                      Marketing consulting
                    </a>
                  </h5>
                  <p className="mb-0">
                    Hey! Just wanted to let you know that the price notification
                    module processes is now live!
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Five