import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const  MuftiMohammedAshfaq = () => {
  return (
    <>
    <Header page={true} />
    <main className="main-area fix mt-5">
        <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
<div>


<section
  className="breadcrumb-area bg--cover "
  style={{
    backgroundImage:
      "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
  }}
>
  <div className="container">
    <div className="breadcrumb-area__content">
      <h2>Mufti Mohammed Ashfaq Kazi</h2>
      <nav aria-label="breadcrumb" className="breadcrumb">
        <span property="itemListElement" typeof="ListItem">
          <a
            property="item"
            typeof="WebPage"
            title="Go to Bitrader."
            href="/"
            className="home"
          >
            <span property="name">Home</span>
          </a>
          <meta property="position" content={1} />
        </span>{" "}
        &gt;{" "}
        <span property="itemListElement" typeof="ListItem">
          <span property="name" className="post post-teams current-item">
          Mufti Mohammed Ashfaq Kazi
          </span>
          {/* <meta
            property="url"
            content="https://bitrader.thetork.com/teams/albert-flores/"
          />
          <meta property="position" content={3} /> */}
        </span>{" "}
      </nav>
    </div>
    <div className="breadcrumb-area__shape">
      <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
        <img
          src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
          alt="Image"
        />
      </span>
    </div>
  </div>
</section>
<div className='p-5 mt-5 mb-5'>

<div className='row'>
    <div className='col-lg-4 col-sm-12 col-md-4'>
        <img src='/Images/team/Mufti_Mohammed_Ashfaq_Kazi.png' className='pe-3' />

    </div>

    <div className='col-lg-7 col-sm-12 col-md-7'>

        <div
          className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
          data-id="2a404d7"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h2 className="elementor-size-default">
            Mufti Mohammed Ashfaq Kazi
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
          data-id="77c0134"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <p className="elementor-size-default">
            He is an eminent Islamic Scholar with over 20 years of experience in Islamic
Jurisprudence (Fiqh & Usool-al-Fiqh). He has been heading the Fatwa
Department and Fiqh committee at the Jama Masjid of Bombay Trust for the
past twelve years. He has been an advisor/consultant for several individuals,
businesses, and corporate firms to review and ensure compliance with
Shariah. He is also the Shariah Advisor for a multinational listed firm at the
Bombay Stock Exchange.
            </p>
          </div>
        </div>
      
 
    </div>
</div>
</div>

</div>
            </div>
            </main>
<Footer />
</>
  )
}

export default  MuftiMohammedAshfaq