import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const FinancialScreening = () => {
    return (
        <>
        <Header page={true} />
        <main className="main-area fix mt-5">
            <div data-elementor-type="wp-page" data-elementor-id="67" className="elementor elementor-67">
    <div>
    
    
    <section
      className="breadcrumb-area bg--cover "
      style={{
        backgroundImage:
          "url(https://bitrader.thetork.com/wp-content/uploads/2024/06/breadcrumb_bg-1.png)"
      }}
    >
      <div className="container">
        <div className="breadcrumb-area__content">
          <h2>Financial Screening Parameters</h2>
          <nav aria-label="breadcrumb" className="breadcrumb">
            <span property="itemListElement" typeof="ListItem">
              <a
                property="item"
                typeof="WebPage"
                title="Go to Bitrader."
                href="/"
                className="home"
              >
                <span property="name">Home</span>
              </a>
              <meta property="position" content={1} />
            </span>{" "}
            &gt;{" "}
            <span property="itemListElement" typeof="ListItem">
              <span property="name" className="post post-teams current-item">
              Financial Screening Parameters
              </span>
              {/* <meta
                property="url"
                content="https://bitrader.thetork.com/teams/albert-flores/"
              />
              <meta property="position" content={3} /> */}
            </span>{" "}
          </nav>
        </div>
        <div className="breadcrumb-area__shape">
          <span className="breadcrumb-area__shape-item breadcrumb-area__shape-item--1">
            <img
              src="https://bitrader.thetork.com/wp-content/themes/bitrader/assets/img/bg/breadcrumb_shape.png"
              alt="Image"
            />
          </span>
        </div>
      </div>
    </section>
    <div className='p-5 mt-5 mb-5'>
    
    <div className='row'>
    <div className='col-lg-5 col-sm-12 col-md-5'>
    <img src='/Images/screnner/3.jpg' className='pe-3' />

    
        </div>
    
        <div className='col-lg-7 col-sm-12 col-md-7'>
    
            <div
              className="elementor-element elementor-element-2a404d7 elementor-widget elementor-widget-heading"
              data-id="2a404d7"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-size-default">
                Financial Screening Parameters
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-77c0134 elementor-widget elementor-widget-heading"
              data-id="77c0134"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
                
              <div className="elementor-widget-container ">
               
                <p className="elementor-size-default">
                In addition to the nature of the business, companies must meet stringent financial criteria to qualify as
Shariah-compliant. These include:
                </p>

                <p className='banner__content-heading title fs-5'>
                <span className='fw-bolder'>1. Total Interest-Based Debt</span>
    
                </p>
                <p className="elementor-size-default">
                The company’s total interest-based debt should not exceed 25% of its Total Assets.
                </p>
                <p className='banner__content-heading title fs-5'>
                <span className='fw-bolder'>2. Interest Income</span>
    
                </p>
                <p className="elementor-size-default">
                The interest income from all sources should not exceed 2.5% of its Total Income.
                </p>
                <p className='banner__content-heading title fs-5'>
                <span className='fw-bolder'>3. Receivables and Cash Holdings</span>
    
                </p>
                <p className="elementor-size-default">
                The company’s receivables, cash, and bank balance should not exceed 90% of its Total
Assets.
                </p>
                <p className='banner__content-heading title fs-5'>
                <span className='fw-bolder'>4. Screening Basis</span>
    
                </p>
                <p className="elementor-size-default">
                Financial assessments are conducted using Consolidated, Audited, Annual Financial
Results.
                </p>
    
    
               
              </div>
            </div>
          
     
        </div>
        
    </div>
    </div>
    
    </div>
                </div>
                </main>
    <Footer />
    </>
      )
}

export default FinancialScreening